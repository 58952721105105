import {
  catchError, mergeMap, of, filter,
} from 'rxjs';
import { ajax } from 'rxjs/ajax';
import {
  validateIpAddressErrorAction,
  validateIpAddressSuccessAction,
  validateIpAddressAction,
} from './app-slice';
import { GATEWAY_EXT_ROOT } from './constants';

const {
  REACT_APP_ALLOW_IP_OVERRIDE,
} = process.env;

const generateIPCheckURL = (testIP) => {
  const ipURL = `${GATEWAY_EXT_ROOT}ip-location`;
  if (testIP && REACT_APP_ALLOW_IP_OVERRIDE) {
    return `${ipURL}?ipAddress=${testIP}`;
  }
  return ipURL;
};

const validateIpAddressEpic = (action$) => action$.pipe(
  filter(validateIpAddressAction.match),
  mergeMap((action) => ajax({
    url: generateIPCheckURL(action.payload.testIP),
    withCredentials: true,
  }).pipe(
    mergeMap((response) => of(validateIpAddressSuccessAction({ response: response.response }))),
    catchError((errorResponse) => of(
      validateIpAddressErrorAction({ response: errorResponse.response }),
    )),
  )),
);

export default [
  validateIpAddressEpic,
];
