import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useMountEffect } from '../../shared/hooks/useMountEffect';
import { googleLoginAction } from '../login-slice';

export default function GoogleLoginButton({ clientId, signUp = false }) {
  const dispatch = useDispatch();
  const BUTTON_ID = 'googleLoginButton';
  const cid = useSelector((state) => state.externalSettings.cid);

  const handleSignIn = (response) => {
    dispatch(googleLoginAction({
      token: response.credential,
      cid,
    }));
  };

  const initializeButton = () => {
    window.google.accounts.id.initialize({
      client_id: clientId,
      callback: handleSignIn,
    });
    window.google.accounts.id.renderButton(
      document.getElementById(BUTTON_ID),
      {
        theme: 'outline',
        size: 'large',
        shape: 'pill',
        width: isMobile ? 285 : 500,
        text: signUp ? 'signup_with' : 'signin_with',
      },
    );
  };

  useMountEffect(() => {
    // If the callback is arleady preset, we have fired this already and it won't
    // work again.
    if (window.google) {
      initializeButton();
    } else {
      window.onGoogleLibraryLoad = () => {
        initializeButton();
      };
    }
  });

  return (
    <div style={{ minWidth: isMobile ? '285px' : '340px', height: 40, overflow: 'hidden' }}>
      <div id={BUTTON_ID} />
    </div>
  );
}

GoogleLoginButton.propTypes = {
  clientId: PropTypes.string.isRequired,
  signUp: PropTypes.bool,
};
