import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  formWrapper: {
    position: 'relative',
    textAlign: 'center',
  },
}));

export default function EmbedLayout({ children }) {
  const classes = useStyles();

  return (
    <div>
      <Grid container spacing={0} justifyContent="center">
        <Grid id="main-section" item xs={12} className={`${classes.formWrapper}`}>
          {children}
        </Grid>
      </Grid>
    </div>
  );
}

EmbedLayout.propTypes = {
  /**
   * The contained child react elements
   */
  children: PropTypes.node,
};

EmbedLayout.defaultProps = {
  children: null,
};
