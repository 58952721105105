import React from 'react';
import PropTypes from 'prop-types';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CheckIcon from '@mui/icons-material/Check';
import InputRequirements from './InputRequirements';

export const PASSWORD_MISSING_NUMBER = 'PASSWORD_MISSING_NUMBER';
export const PASSWORD_MISSING_LOWERCASE = 'PASSWORD_MISSING_LOWERCASE';
export const PASSWORD_MISSING_UPPERCASE = 'PASSWORD_MISSING_UPPERCASE';
export const PASSWORD_TOO_SHORT = 'PASSWORD_TOO_SHORT';
/**
 * Component for rendering password requirements.
 * Requires that the validation schema uses the same errorKeys below for types and messages.
 */
export default function PasswordInputRequirements({ inputKey }) {
  const requirementsConfig = [
    {
      text: '1 lowercase letter',
      normalIcon: FiberManualRecordIcon,
      checkedIcon: CheckIcon,
      errorKey: PASSWORD_MISSING_LOWERCASE,
    },
    {
      text: '1 uppercase letter',
      normalIcon: FiberManualRecordIcon,
      checkedIcon: CheckIcon,
      errorKey: PASSWORD_MISSING_UPPERCASE,
    },
    {
      text: '1 number',
      normalIcon: FiberManualRecordIcon,
      checkedIcon: CheckIcon,
      errorKey: PASSWORD_MISSING_NUMBER,
    },
    {
      text: '8 characters minimum',
      normalIcon: FiberManualRecordIcon,
      checkedIcon: CheckIcon,
      errorKey: PASSWORD_TOO_SHORT,
    },
  ];

  return (
    <InputRequirements
      requiredConfig={requirementsConfig}
      disallowedConfig={[]}
      errorKey={inputKey}
    />
  );
}

PasswordInputRequirements.propTypes = {
  /**
   * The key for the password input element this requirement label is attached to.
   */
  inputKey: PropTypes.string.isRequired,
};
