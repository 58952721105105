import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import makeStyles from '@mui/styles/makeStyles';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import * as yup from 'yup';
import { ValidationProvider } from '@gannettdigital/shared-react-components';
import { yupResolver } from '@hookform/resolvers/yup';
import SectionHeader from '../shared/SectionHeader/SectionHeader';
import EmailInput, { EmailValidation } from '../shared/EmailInput/EmailInput';
import {
  FloatingCard,
  FormGrid,
} from '../themes/DefaultTheme';
import { LOGIN_PATH, SUPPORT_EMAIL } from '../shared/constants';
import { requestResetPasswordAction, resetRequestPasswordErrors } from './forgot-password-slice';
import TextLinkButton from '../shared/TextLinkButton/TextLinkButton';
import ActionCard, { ERROR_TYPE, INFO_TYPE } from '../shared/ActionCard/ActionCard';
import SpinnerCTA from '../shared/SpinnerCTA/SpinnerCTA';
import { trackForgotPWRequest } from '../analytics/analytics-utils';
import { getLoginErrorMessage } from '../shared/utils/login-utils';
import LogoHeader from '../shared/LogoHeader/LogoHeader';

const useStyles = makeStyles((theme) => ({
  formDescription: {
    paddingTop: theme.spacing(1),
    textAlign: 'center',
    margin: '0 auto',
    fontSize: 16,
    lineHeight: '27px',
  },
}));

const ForgotPasswordRoot = styled('div')(({ theme }) => ({
  width: 500,
  margin: '0 auto',
  [theme.breakpoints.down('md')]: {
    height: '100vh',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const PasswordWrapper = styled(FloatingCard)(({ theme }) => ({
  padding: '40px 80px',
  [theme.breakpoints.down('sm')]: {
    padding: '40px 24px',
    margin: '16px',
  },
}));

export default function ForgotPasswordForm() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const FORM_TITLE = 'Forgot your password?';
  const FORM_DESCRIPTION = 'Enter your email address below and we’ll send you an email to reset your password.';
  const sentEmail = useSelector((state) => state.forgotPassword.passwordResetEmailed);
  const isAccountBlocked = useSelector((state) => state.forgotPassword.isAccountBlocked);
  const blockedEmail = useSelector((state) => state.forgotPassword.blockedEmail);
  const errorStatusCode = useSelector((state) => state.forgotPassword.errorStatusCode);
  const failedLoginAttempts = useSelector((state) => state.forgotPassword.failedLoginAttempts);
  const returnToUrl = useSelector((state) => state.externalSettings.redirectUri);
  const isSendingEmail = useSelector((state) => state.loading.forgotPassword);
  const forgotPasswordError = useSelector((state) => state.error.requestPasswordResetError);
  const template = useSelector((state) => state.externalSettings.template);

  const schema = yup.object().shape({
    email: EmailValidation,
  });

  const methods = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
    },
  });

  const { handleSubmit, trigger } = methods;

  const onSubmit = (data) => {
    trackForgotPWRequest();
    dispatch(resetRequestPasswordErrors());

    dispatch(
      requestResetPasswordAction({
        email: data.email,
        returnToUrl: returnToUrl ? encodeURIComponent(returnToUrl) : undefined,
        template: template.key,
      }),
    );
  };

  const handleSignInLink = (e) => {
    e.preventDefault();
    // Reset errors before leaving page
    dispatch(resetRequestPasswordErrors());
    navigate(LOGIN_PATH);
  };

  return (
    <>
      <Helmet>
        <title>Forgot Password | LocaliQ</title>
      </Helmet>
      <LogoHeader />
      <ForgotPasswordRoot>
        {forgotPasswordError && (
          <ActionCard
            id="forgot-password-error-banner"
            type={ERROR_TYPE}
            style={{ marginBottom: 25 }}
          >
            There was an error processing your request. Please try again.
          </ActionCard>
        )}
        {sentEmail && (
          <ActionCard
            id="email-confirmation-prompt"
            type={INFO_TYPE}
            style={{ marginBottom: 25 }}
            textId="reset-email-reset-pw"
          >
            We sent an email to reset your password. Check your spam/junk folder if it does
            not appear in your inbox.
          </ActionCard>
        )}
        {isAccountBlocked && (
          <ActionCard
            id="blocked-user-error-banner"
            type={ERROR_TYPE}
            style={{ marginBottom: 25 }}
          >
            {getLoginErrorMessage(errorStatusCode, blockedEmail, failedLoginAttempts)}
            <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}.</a>
          </ActionCard>
        )}
        <PasswordWrapper>
          <SectionHeader
            title={FORM_TITLE}
            description={FORM_DESCRIPTION}
            descriptionClass={classes.formDescription}
            ids={{
              titleId: 'forgot-password-header',
              descriptionId: 'forgot-password-description'
            }}
          />
          <ValidationProvider schema={schema}>
            <FormProvider {...methods}>
              <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <FormGrid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={2}
                  textAlign="left"
                >
                  <Grid item xs={12}>
                    <EmailInput
                      name="email"
                      id="email-input"
                      disabled={isSendingEmail}
                      trigger={trigger}
                      textId="forgot-email-label"
                      errorId="forget-pw-email-err"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SpinnerCTA
                      variant="contained"
                      id="send-email-btn"
                      type="submit"
                      buttonText="Send Reset Email"
                      showSpinner={isSendingEmail}
                    />
                  </Grid>
                </FormGrid>
              </form>
            </FormProvider>
          </ValidationProvider>
        </PasswordWrapper>
        <TextLinkButton
          text="Remember your password?"
          linkText="Sign in"
          btnPath={LOGIN_PATH}
          linkCallback={handleSignInLink}
          ids={{
            linkId: "forgot-pw-signin-link",
            textId: "remember-pw-text"
          }}
        />
      </ForgotPasswordRoot>
    </>
  );
}
