import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import { AUTH_COOKIE_EXPIRATION_NAME } from './shared/constants';
import DefaultLayout from './layouts/DefaultLayout';
import NotFoundPage from './shared/NotFoundPage/NotFoundPage';

function RequiresAuth({ children }) {
  // Check if this route requires authentication, if so see if a token exists
  // before rendering.
  if ((!Cookies.get(AUTH_COOKIE_EXPIRATION_NAME))) {
    return (
      <DefaultLayout>
        <NotFoundPage />
      </DefaultLayout>
    );
  }

  return children;
}

export default RequiresAuth;

RequiresAuth.propTypes = {
  /**
   * Child nodes to render
   */
  children: PropTypes.node,
};
