import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import CreateBusinessFormContainer from './CreateBusinessFormContainer';
import { useMountEffect } from '../shared/hooks/useMountEffect';
import LoadingModal from '../shared/LoadingModal/LoadingModal';

export default function CreateBusinessPage() {
  const template = useSelector((state) => state.externalSettings.template);
  const createBusinessInProgress = useSelector((state) => state.loading.createBusiness);
  const createBusinessError = useSelector((state) => state.error.createBusinessError);
  const [pageMounted, setPageMounted] = useState(false);

  useMountEffect(() => {
    setPageMounted(true);
  });

  return (
    <>
      <Helmet>
        Create Business | LocaliQ
      </Helmet>
      {pageMounted && <CreateBusinessFormContainer createBusinessError={createBusinessError} />}
      {
        pageMounted && createBusinessInProgress && (
          <LoadingModal
            open={createBusinessInProgress}
            headerText={template.createBusiness.loading.headerText}
            footerText={template.createBusiness.loading.footerText}
            progressTextReference={template.createBusiness.loading.progressRef}
            textSwitchIntervalSeconds={3}
          />
        )
      }
    </>
  );
}
