import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LOGIN_PATH } from './shared/constants';

function RedirectToSignIn() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    navigate({
      pathname: LOGIN_PATH,
      search: location.search,
    });
  }, []);

  return null;
}

export default RedirectToSignIn;
