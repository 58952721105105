import {
  Link,
  Typography,
} from '@mui/material';
import styled from '@emotion/styled';

const GoogleLink = styled(Link)(() => ({
  wordBreak: 'break-word',
  paddingLeft: 5,
  textDecoration: 'underline',
}));

/**
 * Whenever the google recaptcha logo/icon is hidden, this prompt
 * must be shown somewhere on the page.
 */
export default function GreCaptchaPrompt({ ...props }) {
  return (
    <Typography className="grecaptcha-prompt" variant="body2" {...props}>
      This site is protected by reCAPTCHA and the Google
      <GoogleLink
        href="https://policies.google.com/privacy"
        color="primary"
        variant="inherit"
        rel="noopener"
        target="_blank"
        sx={{ paddingRight: '5px' }}
      >
        Privacy Policy
      </GoogleLink>
      and
      <GoogleLink
        href="https://policies.google.com/terms"
        color="primary"
        variant="inherit"
        rel="noopener"
        target="_blank"
        sx={{ paddingRight: '5px' }}
      >
        Terms of Service
      </GoogleLink>
      apply.
    </Typography>
  );
}
