import styled from '@emotion/styled';
import { IQButtonLink } from '@gannettdigital/shared-react-components';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  precedingText: {
    textAlign: 'center',
  },
}));

const CustomLinkBtn = styled(IQButtonLink)(({ theme }) => ({
  verticalAlign: 'baseline',
  fontSize: 16,
  paddingLeft: theme.spacing(1),
  '&.Mui-disabled': {
    backgroundColor: 'transparent',
  },
}));

/**
 * A component used displaying some text with a link embedded.
 * See PropTypes param for info.
 */
export default function TextLinkButton({
  text,
  buttonId,
  linkText,
  linkCallback,
  btnPath,
  ids,
  ...props
}) {
  const classes = useStyles();

  return (
    <Typography
      className={classes.precedingText}
      variant="body1"
      id={ids?.textId}
      {...props}
    >
      {text}
      {linkText && <CustomLinkBtn
        id={ids?.linkId}
        onClick={linkCallback}
        href={btnPath}
        disabled={props.disabled}
      >
        {linkText}
      </CustomLinkBtn>}
    </Typography>
  );
}

TextLinkButton.propTypes = {
  /**
   * The text preceding the link
   */
  text: PropTypes.string.isRequired,

  /**
   * Text of the link
   */
  linkText: PropTypes.string.isRequired,

  /**
   * Callback function for when the link is clicked
   */
  linkCallback: PropTypes.func.isRequired,
};
