import { configureStore } from '@reduxjs/toolkit';
import { createEpicMiddleware } from 'redux-observable';
import rootEpic from './root-epic';
import errorSlice from '../shared/error-slice';
import forgotPasswordSlice from '../ForgotPassword/forgot-password-slice';
import createAccountSlice from '../CreateAccount/create-account-slice';
import createBusinessSlice from '../CreateBusiness/create-business-slice';
import loginSlice from '../Login/login-slice';
import logoutSlice from '../Logout/logout-slice';
import appSlice from '../shared/app-slice';
import loadingSlice from '../shared/LoadingCard/loading-slice';

const { REACT_APP_LABEL } = process.env;

const epicMiddleware = createEpicMiddleware();

export const store = configureStore({
  reducer: {
    externalSettings: appSlice,
    forgotPassword: forgotPasswordSlice,
    createAccount: createAccountSlice,
    createBusiness: createBusinessSlice,
    login: loginSlice,
    logout: logoutSlice,
    loading: loadingSlice,
    error: errorSlice,
  },
  middleware: [epicMiddleware],
  devTools: REACT_APP_LABEL !== 'PRODLABEL',
});

epicMiddleware.run(rootEpic);
