import { useMemo } from 'react';
import {
  Container,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { IQButtonLink, IQFormInput, ValidationProvider } from '@gannettdigital/shared-react-components';
import { useNavigate } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { GoogleReCaptcha, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import * as yup from 'yup';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { createAccountAction, resetCreateAccountError } from './create-account-slice';
import {
  FormGrid,
} from '../themes/DefaultTheme';
import PasswordInput, { PasswordCreationValidation } from '../shared/PasswordInput/PasswordInput';
import EmailInput, { EmailValidation } from '../shared/EmailInput/EmailInput';
import TextLinkButton from '../shared/TextLinkButton/TextLinkButton';
import {
  FIRST_NAME_IS_REQUIRED_MESSAGE,
  FIRST_NAME_NO_SPACES_MESSAGE,
  LAST_NAME_IS_REQUIRED_MESSAGE,
  LAST_NAME_NO_SPACES_MESSAGE,
  LOGIN_EMBED_PATH,
  LOGIN_PATH,
  NO_SPACES_REGEX,
  privacyPolicyLink,
  selfServiceTerm,
} from '../shared/constants';
import { PrimaryButton } from '../shared/styled/shared-styled-components';
import ActionCard, { ERROR_TYPE, WARNING_TYPE } from '../shared/ActionCard/ActionCard';

const useStyles = makeStyles((theme) => ({
  createBtnWrapper: {
    '&.MuiGrid-item': {
      paddingTop: 0,
    },
  },
  termsWrapper: {
    textAlign: 'left',
    wordBreak: 'break-word',
    '&.MuiGrid-item': {
      paddingTop: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(2),
      },
    },
  },
  topInputWrapper: {
    '&.MuiGrid-item': {
      paddingTop: theme.spacing(1),
    },
  },
  termsLink: {
    wordBreak: 'break-word',
    padding: '0 5px',
    textDecoration: 'underline',
  },
  privacyLink: {
    paddingRight: 5,
    textDecoration: 'underline',
  },
  signInWrapper: {
    '&.MuiGrid-item': {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(8),
    },
  },
  signInLink: {
    padding: 0,
    position: 'relative',
    left: -5,
    top: -1,
  },
}));

export default function CreateAccountEmbedForm() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const template = useSelector((state) => state.externalSettings.template);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const defaultEmail = useSelector((state) => state.externalSettings.prePopulateEmail);
  const defaultFirstName = useSelector((state) => state.externalSettings.prePopulateFirstName);
  const defaultLastName = useSelector((state) => state.externalSettings.prePopulateLastName);
  const createAccountError = useSelector((state) => state.error.accountCreationError);
  const duplicateAccountError = useSelector((state) => state.error.duplicateError);
  const existingPlatformUserError = useSelector((state) => state.error.existingPlatformUserError);

  const schema = yup.object().shape({
    email: EmailValidation,
    password: PasswordCreationValidation,
    firstName: yup.string()
      .required(FIRST_NAME_IS_REQUIRED_MESSAGE)
      .matches(NO_SPACES_REGEX, FIRST_NAME_NO_SPACES_MESSAGE),
    lastName: yup.string()
      .required(LAST_NAME_IS_REQUIRED_MESSAGE)
      .matches(NO_SPACES_REGEX, LAST_NAME_NO_SPACES_MESSAGE),
  });

  const methods = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
    resolver: yupResolver(schema),
    defaultValues: useMemo(() => ({
      firstName: defaultFirstName || '',
      lastName: defaultLastName || '',
      email: defaultEmail || '',
    }), [defaultEmail, defaultFirstName, defaultLastName]),
  });

  const {
    handleSubmit,
    trigger
  } = methods;

  const onSubmit = async (data) => {
    resetCreateAccountError();
    const token = await executeRecaptcha('CREATE_ACCOUNT');
    dispatch(createAccountAction({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      password: data.password,
      telephoneNumber: null,
      recaptchaToken: token,
    }));
  };

  const handleSignInLink = () => {
    // Before we leave, reset any errors we see.
    dispatch(resetCreateAccountError());
    navigate(LOGIN_EMBED_PATH);
  };

  return (
    <Container>
      {createAccountError && (
        <ActionCard
          id="general-account-error-banner"
          type={ERROR_TYPE}
        >
          An error has occured and we were unable to create your account. Please try again.
        </ActionCard>
      )}
      {existingPlatformUserError && (
        <ActionCard
          id="existing-platform-user-banner"
          type={WARNING_TYPE}
        >
          Looks like you already have a LocaliQ account. Contact your current rep
          {' '}
          to learn more about your LOCALiQ and the solutions that are right for your business.
        </ActionCard>
      )}
      {duplicateAccountError && (
        <ActionCard
          id="duplicate-account-banner"
          type={WARNING_TYPE}
        >
          An account with the specified email already exists. Please
          <IQButtonLink
            id="sign-in-link"
            className={classes.signInLink}
            onClick={handleSignInLink}
          >
            sign in.
          </IQButtonLink>
        </ActionCard>
      )}
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <form id="embed-registration-form" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <GoogleReCaptcha />
            <FormGrid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
              textAlign="left"
            >
              <Grid item xs={12} className={classes.topInputWrapper}>
                <IQFormInput
                  name="firstName"
                  id="first-name"
                  labelText="First Name"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <IQFormInput
                  name="lastName"
                  id="last-name"
                  labelText="Last Name"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <EmailInput
                  name="email"
                  id="email-input"
                  defaultEmail={defaultEmail}
                  trigger={trigger}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <PasswordInput
                  id="create-password"
                  name="password"
                  label="Create Password"
                  showRequirements
                />
              </Grid>
              <Grid item xs={12} className={classes.termsWrapper}>
                <Typography variant="caption">
                  By clicking the “Get Started” button, you agree to our
                  <Link
                    className={classes.termsLink}
                    href={selfServiceTerm}
                    color="primary"
                    variant="inherit"
                    rel="noopener"
                    target="_blank"
                  >
                    Self-Service Terms,
                  </Link>
                  <Link
                    className={`${classes.privacyLink}`}
                    href={privacyPolicyLink}
                    color="primary"
                    variant="inherit"
                    rel="noopener"
                    target="_blank"
                  >
                    Privacy Policy
                  </Link>
                  and to receive B2B marketing communications from LocaliQ at the email and number you provided. To delete your free account, email <a href={`mailto:${SUPPORT_EMAIL}?subject=LocaliQ Assistance`} target="_blank">{SUPPORT_EMAIL}</a>. HIPAA customers are not eligible for free products.
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.createBtnWrapper}>
                <PrimaryButton
                  sx={{ mt: theme.spacing(3) }}
                  variant="contained"
                  id="create-acct-btn"
                  type="submit"
                >
                  {template.createAccount.ctaText || 'Create Account'}
                </PrimaryButton>
              </Grid>
              <Grid item xs={12} className={classes.signInWrapper}>
                <TextLinkButton
                  text="Already have an account?"
                  linkText="Sign in"
                  btnPath={LOGIN_PATH}
                  linkCallback={handleSignInLink}
                />
              </Grid>
            </FormGrid>
          </form>
        </FormProvider>
      </ValidationProvider>
    </Container>
  );
}
