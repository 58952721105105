import { createSlice } from '@reduxjs/toolkit';
import { redirectAction } from '../shared/shared-actions';

// needed for analytics since it was used before redux-toolkit
export const CREATE_ACCOUNT_EXISTING_PLATFORM_USER_ERROR_TYPE = 'EXISTING_PLATFORM_USER_ERROR';

const createAccountSlice = createSlice({
  name: 'createAccount',
  initialState: {
    redirectPath: null,
  },
  reducers: {
    createAccountAction: () => {},
    createAccountSuccessAction: () => {},
    resetCreateAccountError: () => {},
    createAccountFinishedLoadingAction: () => {},
    createAccountDuplicateEmailErrorAction: () => {},
    createAccountFailureAction: () => {},
    createAccountExistingPlatformUserErrorAction: () => {},
  },
  extraReducers: (builder) => {
    builder.addCase(redirectAction, (state, action) => {
      state.redirectPath = action.payload.redirectPath;
    });
  },
});

export const {
  createAccountAction,
  createAccountSuccessAction,
  resetCreateAccountError,
  createAccountFinishedLoadingAction,
  createAccountDuplicateEmailErrorAction,
  createAccountFailureAction,
  createAccountExistingPlatformUserErrorAction,
} = createAccountSlice.actions;

export default createAccountSlice.reducer;
