

import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import localIqImg from '../../images/logo-localiq.svg';

const HeaderWrapper = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5),
}));

export default function LogoHeader() {
  const template = useSelector((state) => state.externalSettings.template);

  return (
      <HeaderWrapper> 
        <img src={template && template.logo ? template.logo : localIqImg} id="register-localiq-loco" alt="LocaliQ Logo" />
      </HeaderWrapper> 
  );
};