/**
 * Supports a custom endsWith method for browsers that don't have native support
 */
export const strEndsWith = (str, suffix) => {
  if (typeof String.prototype.endsWith !== 'function') {
    return this.indexOf(suffix, this.length - suffix.length) !== -1;
  }

  return str.endsWith(suffix);
};

export const isValidUSPhoneNumberLength = (str) => {
  const digitsText = str.replace(/\D/g, '');

  return digitsText.length === 11;
};
