import PropTypes from 'prop-types';
import { FormProvider } from 'react-hook-form';
import styled from '@emotion/styled';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { IQFormInput, IQFormPhoneInput } from '@gannettdigital/shared-react-components';
import { ContentFormGrid, PrimaryButton } from '../shared/styled/shared-styled-components';

const PhoneGrid = styled(Grid)(({ theme }) => ({
  '&  div.MuiFormControl-root': {
    '& label': {
      color: theme.palette.common.black,
    },
    '& div': {
      '&:focus-within': {
        margin: 0,
        borderWidth: 1,
      },
    },
  },
}));

const CustomButton = styled(PrimaryButton)(({ theme }) => ({
  borderRadius: '12px'
}));

const CustomTitle = styled(Typography)(({ theme }) => ({
  marginBottom: '24px',
  fontSize: '24px',
  fontFamily: 'Unify Sans SemiBold',
  textAlign: 'center',
  marginTop: 0,
}));

export default function CreateBusinessForm({ methods, onSubmit, isWebsiteRequired }) {
  const template = useSelector((state) => state.externalSettings.template);
  const FORM_CTA_TEXT = template.createBusiness.ctaText || 'Create Account';
  const responsiveCheck = {
    maxWidth: 500,
    width: isMobile ? 'auto' : 500,
  };

  return (
    <FormProvider {...methods}>
      <form
        autoComplete="off"
        onSubmit={methods.handleSubmit(onSubmit)}
        style={responsiveCheck}
      >
        <ContentFormGrid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12} style={{ paddingTop: 0 }}>
            <CustomTitle id="social-signin-title">Tell us about your business.</CustomTitle>
          </Grid>
          <Grid item xs={12}>
            <IQFormInput
              name="businessName"
              id="business-name"
              labelText="Business Name"
              maxLength={64}
              fullWidth
              inputRadius="12px"
            />
          </Grid>
          <Grid item xs={12}>
            <IQFormInput
              name="websiteUrl"
              id="website-url"
              labelText={isWebsiteRequired ? 'Website URL' : 'Website URL (Optional)'}
              maxLength={255}
              fullWidth
              inputRadius="12px"
            />
          </Grid>
          <PhoneGrid id="business-phone-number-group" item xs={12}>
            <IQFormPhoneInput
              name="businessPhoneNumber"
              id="business-phone-number"
              labelText="Business Phone Number (Optional)"
              onChange={() => { }}
              fullWidth
              inputRadius="12px"
            />
          </PhoneGrid>
          <Grid item xs={12}>
            <CustomButton
              variant="contained"
              id="create-bsn-btn"
              type="submit"
              disabled={!methods.formState.isValid}
            >
              {FORM_CTA_TEXT}
            </CustomButton>
          </Grid>
        </ContentFormGrid>
      </form>
    </FormProvider>
  );
}

CreateBusinessForm.propTypes = {
  /**
   * Form methods to consume
   */
  methods: PropTypes.object.isRequired,
  /**
   * On Submit callback function
   */
  onSubmit: PropTypes.func.isRequired,
  /**
   * Determines if website url should be a required field
   */
  isWebsiteRequired: PropTypes.bool,
};
