import ActionCard, { ERROR_TYPE } from '../shared/ActionCard/ActionCard';
import { SUPPORT_EMAIL } from '../shared/constants';

export default function CreateBusinessFormErrors() {
  return (
    <ActionCard
      id="general-business-error-banner"
      type={ERROR_TYPE}
      style={{ marginBottom: 25 }}
    >
      Oh no! There was issue creating your account. Please try again or contact
      {' '}
      <b>{SUPPORT_EMAIL}</b>
    </ActionCard>
  );
}
