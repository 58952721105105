import styled from '@emotion/styled';
import {
  createTheme, Button, Card, Grid, LinearProgress,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { IQThemeV2 } from '@gannettdigital/shared-react-components';

// Add any v2 theme overrides here
const themeOverrides = {
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& label': {
            fontFamily: 'Unify Sans SemiBold',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
          ':hover': {
            backgroundColor: '#0035C2',
          },
          ':focus': {
            outline: 'none',
            border: '1px solid #FFFFFF',
            boxShadow: '0px 0px 0px 3px #6982C8',
            // Keeps the page from moving when the button outline + box shadows.
            margin: '-1px',
          },
        },
      },
    },
  },
};

export const DefaultTheme = createTheme(IQThemeV2, themeOverrides);

export const PrimaryLinkButton = withStyles(({ palette }) => ({
  root: {
    color: palette.primary.main,
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: '0.25px',
    textTransform: 'none',
    textDecoration: 'underline',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
}))(Button);

export const FloatingCard = styled(Card)(({ stacked, theme }) => ({
  backgroundColor: theme.palette.common.white,
  border: `1px solid #CECFD0`,
  borderRadius: 8,
  [theme.breakpoints.down('md')]: {
    borderTopWidth: stacked ? 0 : '1px',
  },
}));

export const FormGrid = withStyles(() => ({
  root: {
    margin: '0 auto',
    width: '100%',
  },
  'spacing-xs-2': {
    '& > .MuiGrid-item': {
      paddingTop: 24,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}))(Grid);

export const BasicLinearProgress = withStyles((theme) => ({
  root: {
    height: 20,
    borderRadius: 10,
    boxShadow: `0px 0px 0px 1px ${theme.palette.action.disabledBackground}`,
  },
  colorPrimary: {
    backgroundColor: theme.palette.common.white,
    animation: `$pulse 2000ms ${theme.transitions.easing.easeInOut}`,
    animationIterationCount: 'infinite',
  },
  bar: {
    borderRadius: 13,
    backgroundColor: theme.palette.success.light,
  },
  '@keyframes pulse': {
    '0%': {
      backgroundColor: theme.palette.common.white,
    },
    '50%': {
      backgroundColor: theme.palette.info.light,
    },
    '100%': {
      backgroundColor: theme.palette.common.white,
    },
  },
}))(LinearProgress);
