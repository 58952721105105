import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import localIqImg from '../images/logo-localiq.svg';
import { CREATE_BUSINESS_PATH, EUROPEAN_UNION_COUNTRY_CODES, NOT_ALLOWED_PATH } from '../shared/constants';

const RootWrapper = styled('div')(() => ({
  overflowX: 'clip',
  position: 'relative',
}));

const GridWrapper = styled(Grid)(({ theme }) => ({
  minHeight: '100vh',
  backgroundColor: theme.palette.background.default,
  marginBottom: `-${theme.spacing(3)}`,
  justifyContent: 'center',
  flexDirection: 'column',
  alignContent: 'center',
  alignItems: 'center',
}));

const HeaderWrapper = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5),
}));

const FormWrapper = styled(Grid)(() => ({
  position: 'relative',
  textAlign: 'center',
  zIndex: 2,
  width: '100%',
}));

export default function DefaultLayout({ children }) {
  const missingBusinessInfo = useSelector((state) => state.error.missingBusinessInfo);
  const countryData = useSelector((state) => state.externalSettings.ipAddressValidationData);

  const navigate = useNavigate();

  useEffect(() => {
    if (missingBusinessInfo) {
      navigate(CREATE_BUSINESS_PATH);
    }
  }, [missingBusinessInfo]);

  useEffect(() => {
    if (countryData && EUROPEAN_UNION_COUNTRY_CODES.includes(countryData.countryCode)) {
      navigate(NOT_ALLOWED_PATH);
    }
  }, [countryData]);

  return (
    <RootWrapper>
      <GridWrapper container spacing={0}>
        <FormWrapper id="main-section" item xs={12}>
          {children}
        </FormWrapper>
      </GridWrapper>
    </RootWrapper>
  );
}

DefaultLayout.propTypes = {
  /**
   * The contained child react elements
   */
  children: PropTypes.node,
};

DefaultLayout.defaultProps = {
  children: null,
};
