import { of } from 'rxjs';
import { catchError, mergeMap, filter } from 'rxjs/operators';
import { tracker } from '@gannettdigital/localiq-dms-analytics-tracker';
import { ajax } from 'rxjs/ajax';
import ls from 'local-storage';
import {
  createBusinessAction,
  createBusinessSuccessAction,
  createBusinessErrorAction,
} from './create-business-slice';
import {
  BACKEND_ROOT, SEGMENT_LS_KEY, SOURCE_REGISTER,
} from '../shared/constants';
import { handleSiteRedirect } from '../shared/utils/app-utils';
import { emptyAction } from '../shared/shared-actions';

const handleCreateError = (error) => of(createBusinessErrorAction(error));

const createBusinessEpic = (action$) => action$.pipe(
  filter(createBusinessAction.match),
  mergeMap((action) => ajax({
    url: `${BACKEND_ROOT}save_business`,
    method: 'POST',
    withCredentials: true,
    body: {
      businessName: action.payload.businessName,
      businessUrl: action.payload.websiteUrl,
      businessPhone: action.payload.businessPhoneNumber,
      segment: Number(ls.get(SEGMENT_LS_KEY)),
      browserTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      distinctId: tracker.getTrackingId(),
    },
  }).pipe(
    mergeMap((response) => {
      ls.remove(SEGMENT_LS_KEY);
      return of(createBusinessSuccessAction({
        success: response.response.success,
        authToken: response.response.authToken,
      }));
    }),
    catchError((error) => handleCreateError(error)),
  )),
);

const createBusinessSuccessEpic = (action$, state$) => action$.pipe(
  filter(createBusinessSuccessAction.match),
  mergeMap((action) => {
    handleSiteRedirect(
      state$.value.externalSettings.redirectUri,
      SOURCE_REGISTER,
      action.payload.authToken,
    );
    return of(emptyAction());
  }),
);

export default [
  createBusinessEpic,
  createBusinessSuccessEpic,
];
