import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { FloatingCard } from '../../themes/DefaultTheme';

const ContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const LeftSideWrapper = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5),
  [theme.breakpoints.down('md')]: {
    paddingLeft: 0,
    order: 2,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

const RightSideWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    order: 1,
  },
}));

const SplitFloatingCard = styled(FloatingCard)(({ theme }) => ({
  width: 864,
  margin: '0 auto',
  padding: 0,
  [theme.breakpoints.down('md')]: {
    maxWidth: 864,
    width: 'auto',
  },
}));

/**
 * Floating card with a left and right side.
 * In mobile, right side sits on top of left side.
 *
 * See below prop types for param info
 */
export default function SplitCard({
  leftSideContent,
  rightSideContent,
}) {
  return (
    <SplitFloatingCard
      stacked="true"
    >
      <ContentWrapper>
        <LeftSideWrapper>
          {leftSideContent}
        </LeftSideWrapper>
        <RightSideWrapper>
          {rightSideContent}
        </RightSideWrapper>
      </ContentWrapper>
    </SplitFloatingCard>
  );
}

SplitCard.propTypes = {
  leftSideContent: PropTypes.node,
  rightSideContent: PropTypes.node,
};

SplitCard.defaultProps = {
  leftSideContent: null,
  rightSideContent: null,
};
