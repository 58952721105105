import { of } from 'rxjs';
import {
  catchError, map, mergeMap, filter,
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import ls from 'local-storage';
import { tracker } from '@gannettdigital/localiq-dms-analytics-tracker';
import {
  googleLoginAction,
  loginSuccessAction,
  loginAction,
  loginFailureAction,
  tokenInvalid,
  tokenValid,
  validateAuth,
  validateAuthFailure,
} from './login-slice';
import {
  BACKEND_ROOT,
  CREATE_BUSINESS_PATH,
  SOURCE_LOGIN,
  TEMPLATE_LS_KEY,
  SEGMENT_LS_KEY,
  ACCOUNT_TYPE_PREMIUM,
  ACCOUNT_TYPE_FREEMIUM,
} from '../shared/constants';
import { handleSiteRedirect } from '../shared/utils/app-utils';
import { emptyAction, redirectAction } from '../shared/shared-actions';
import { inIframe, postMessageWrapper } from '../shared/utils/iframe-utils';
import {
  GOOGLE_TYPE, LOCALIQ_TYPE, signInFailure, signInSuccess,
} from '../analytics/analytics-utils';
import { GOOGLE_REGISTRATION_SUCCESS_EVENT_NAME } from '../analytics/analytics-constants';
import {
  createAccountExistingPlatformUserErrorAction,
  createAccountSuccessAction,
  CREATE_ACCOUNT_EXISTING_PLATFORM_USER_ERROR_TYPE,
} from '../CreateAccount/create-account-slice';
import { createBusinessExistingUserAction } from '../CreateBusiness/create-business-slice';
import { buildGoogleRegisterGAData, trackGAEvent } from '../analytics/tag-manager-utils';

const { REACT_APP_CLIENT_CENTER_HOST } = process.env;

const handleCreateError = (error, loginType) => {
  const errorResponse = error.response;
  const responseMessage = {
    type: 'LOGIN_FAILURE',
  };
  if (inIframe()) {
    postMessageWrapper(JSON.stringify(responseMessage));
  }

  if (error.status === 503) {
    signInFailure(503, loginType);
    return of(loginFailureAction(error));
  }

  if (errorResponse && errorResponse.statusCode === 1004) {
    signInFailure(CREATE_ACCOUNT_EXISTING_PLATFORM_USER_ERROR_TYPE, loginType);
    return of(createAccountExistingPlatformUserErrorAction(error));
  }

  signInFailure(errorResponse.statusCode, loginType);

  return of(loginFailureAction(error));
};

const loginEpic = (action$) => action$.pipe(
  filter(loginAction.match),
  mergeMap((action) => ajax({
    url: `${BACKEND_ROOT}login`,
    method: 'POST',
    withCredentials: true,
    body: {
      email: action.payload.email,
      password: action.payload.password,
      template: ls.get(TEMPLATE_LS_KEY) || 'default',
      loginType: LOCALIQ_TYPE,
    },
  }).pipe(
    mergeMap((response) => {
      ls.remove(TEMPLATE_LS_KEY);
      return of(loginSuccessAction({
        firstName: response.response.firstName,
        lastName: response.response.lastName,
        email: response.response.email,
        authToken: response.response.authToken,
        source: LOCALIQ_TYPE,
        businessName: response.response.businessName,
        businessUrl: response.response.businessUrl,
        businessPhone: response.response.businessPhone,
        accountTypes: response.response.accountTypes,
      }));
    }),
    catchError((error) => handleCreateError(error, LOCALIQ_TYPE)),
  )),
);

const googleLoginEpic = (action$, state$) => action$.pipe(
  filter(googleLoginAction.match),
  mergeMap((action) => ajax({
    url: `${BACKEND_ROOT}social_login`,
    method: 'POST',
    withCredentials: true,
    body: {
      token: action.payload.token,
      cid: action.payload.cid,
      template: ls.get(TEMPLATE_LS_KEY) || 'default',
      distinctId: tracker.getTrackingId(),
      segment: Number(ls.get(SEGMENT_LS_KEY)),
    },
  }).pipe(
    mergeMap((response) => {
      ls.remove(TEMPLATE_LS_KEY);
      if (response.response.newUser) {
        trackGAEvent(
          GOOGLE_REGISTRATION_SUCCESS_EVENT_NAME,
          buildGoogleRegisterGAData(state$.value.externalSettings.redirectUri, action.payload.cid),
        );
        return of(createAccountSuccessAction({
          firstName: response.response.firstName,
          lastName: response.response.lastName,
          email: response.response.email,
          authToken: response.response.authToken,
          source: GOOGLE_TYPE,
          businessName: response.response.businessName,
          businessUrl: response.response.businessUrl,
          businessPhone: response.response.businessPhone,
          accountTypes: response.response.accountTypes,
        }));
      }
      return of(loginSuccessAction({
        firstName: response.response.firstName,
        lastName: response.response.lastName,
        email: response.response.email,
        authToken: response.response.authToken,
        source: GOOGLE_TYPE,
        businessName: response.response.businessName,
        businessUrl: response.response.businessUrl,
        businessPhone: response.response.businessPhone,
        newUser: response.response.newUser,
        accountTypes: response.response.accountTypes,
      }));
    }),
    catchError((error) => handleCreateError(error, GOOGLE_TYPE)),
  )),
);

// TODO: Should we keep these incase something specific to login or create account is required but
// also add a generic one for the redirect / token storage?
const loginSuccessEpic = (action$, state$) => action$.pipe(
  filter(loginSuccessAction.match),
  mergeMap((action) => {
    // Fire off analytics tracking to identify the user, then the successful sign in
    signInSuccess(action.payload.email, action.payload.source, () => {
      if (inIframe()) {
        const responseMessage = {
          type: 'LOGIN_SUCCESS',
          // TODO: Add in auth token once sso merged?
        };
        postMessageWrapper(JSON.stringify(responseMessage));
      }
    });

    const isPremium = action.payload.accountTypes.indexOf(ACCOUNT_TYPE_PREMIUM) > -1;
    const isFreemium = action.payload.accountTypes.indexOf(ACCOUNT_TYPE_FREEMIUM) > -1;

    if (isFreemium && !action.payload.businessName && !isPremium) {
      // Freemium goes to business screen ONLY if
      // business data is missing, otherwise it
      // goes to the return to url
      return of(
        redirectAction({ redirectPath: CREATE_BUSINESS_PATH }),
        createBusinessExistingUserAction(),
      );
    }

    let returnToUrl = state$.value.externalSettings.redirectUri;
    if (isPremium && !returnToUrl) {
      returnToUrl = `${REACT_APP_CLIENT_CENTER_HOST}/sso/freemium_login`;
    }

    window.setTimeout(() => {
      handleSiteRedirect(
        returnToUrl,
        SOURCE_LOGIN,
        action.payload.authToken,
      );
    }, 3000);

    return of(emptyAction());
  }),
);

const validateAuthEpic = (action$) => action$.pipe(
  filter(validateAuth.match),
  mergeMap((action) => ajax({
    url: `${BACKEND_ROOT}validate_auth`,
    method: 'POST',
    withCredentials: true,
    body: {},
  }).pipe(
    map((response) => {
      const isTokenValid = response.response.validToken;
      if (isTokenValid) {
        return tokenValid({
          authToken: response.response.authToken,
          source: action.payload.source,
          businessName: response.response.businessName,
          accountTypes: response.response.accountTypes,
        });
      }
      return tokenInvalid();
    }),
    catchError((error) => of(validateAuthFailure(error))),
  )),
);

const validTokenEpic = (action$, state$) => action$.pipe(
  filter(tokenValid.match),
  mergeMap((action) => {
    const isPremium = action.payload.accountTypes.indexOf(ACCOUNT_TYPE_PREMIUM) > -1;
    const isFreemium = action.payload.accountTypes.indexOf(ACCOUNT_TYPE_FREEMIUM) > -1;

    if (isFreemium && !action.payload.businessName && !isPremium) {
      // Freemium goes to business screen ONLY if
      // business data is missing, otherwise it
      // goes to the return to url
      return of(
        redirectAction({ redirectPath: CREATE_BUSINESS_PATH }),
        createBusinessExistingUserAction(),
      );
    }

    let returnToUrl = state$.value.externalSettings.redirectUri;
    if (isPremium && !returnToUrl) {
      returnToUrl = `${REACT_APP_CLIENT_CENTER_HOST}/sso/freemium_login`;
    }

    handleSiteRedirect(
      returnToUrl,
      action.payload.source,
      action.payload.authToken,
    );

    // If missing an error is set which will redirect to the business page
    return of(emptyAction());
  }),
);

export default [
  loginEpic,
  loginSuccessEpic,
  validateAuthEpic,
  validTokenEpic,
  googleLoginEpic,
];
