import { useEffect, useState, useCallback } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import {
  Backdrop, Box, Divider, Modal, Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { BasicLinearProgress } from '../../themes/DefaultTheme';

const TitleDivider = styled(Divider)(({ theme }) => ({
  paddingTop: theme.spacing('23px'), // 1px border size
  borderImage: 'linear-gradient(to right, #6432BE 0%, #DC4605 100%) 0 0 100% 0',
}));

const ProgressText = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  lineHeight: '25px',
  letterSpacing: '0.18px',
  paddingTop: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    fontSize: 'inherit',
    lineHeight: 'inherit',
    letterSpacing: 'inherit',
  },
}));

const useStyles = makeStyles((theme) => ({
  loadingRoot: {
    position: 'absolute',
    width: '45%',
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.info.dark}`,
    borderRadius: 5,
    boxShadow: 'none',
    padding: theme.spacing(3),
    top: '18%',
    left: '50%',
    transform: 'translate(-50%, -20%)',
    textAlign: 'center',
    color: theme.palette.common.black,
    '&:focus': {
      outline: 'none',
    },
    [theme.breakpoints.down('md')]: {
      width: '80%',
    },
  },
  contentWrapper: {
    width: '80%',
    margin: '0 auto',
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      paddingBottom: 0,
    },
  },
  progressWrapper: {
    padding: theme.spacing(3, 0),
    width: '80%',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  progressBar: () => ({
    background: `linear-gradient(90deg, #0046FE 0%, #6F10F4 31.25%, #EB3776 67.71%, #FF6436 100%)`,
  }),
}));

export default function LoadingModal({
  open, headerText, footerText, progressTextReference, textSwitchIntervalSeconds,
}) {
  const [progress, setProgress] = useState(33);
  const [progressText, setProgressText] = useState(progressTextReference[33]);
  const classes = useStyles({ progress });
  let intervalTracker = null;

  const updateProgression = useCallback(() => {
    if (!open) {
      setProgress(33);
      setProgressText(progressTextReference[33]);
      clearInterval(intervalTracker);
      return;
    }

    if (progress < 99) {
      setProgress(progress + 33);
      setProgressText(progressTextReference[progress + 33]);
    }
  }, [open, progress, setProgress, setProgressText, progressTextReference, intervalTracker]);

  useEffect(() => {
    intervalTracker = setInterval(
      updateProgression,
      textSwitchIntervalSeconds * 1000,
    );
    return () => clearInterval(intervalTracker);
  });

  return (
    <Modal
      components={{
        Backdrop,
      }}
      open={open}
      disableEnforceFocus
      aria-describedby="loading-modal-title"
    >
      <div className={classes.loadingRoot}>
        <Typography id="loading-modal-title" variant="h4">{headerText}</Typography>
        <TitleDivider />
        <div className={classes.contentWrapper}>
          <ProgressText variant="body1">{progressText}</ProgressText>
          <Box className={classes.progressWrapper}>
            <BasicLinearProgress
              classes={{ bar: classes.progressBar }}
              variant="determinate"
              value={progress}
            />
          </Box>
          <Typography variant="body1" sx={{ fontSize: { xs: '12px', md: '1rem' } }}>{footerText}</Typography>
        </div>
      </div>
    </Modal>
  );
}

LoadingModal.propTypes = {
  /**
   *  The boolean flag to open/close the modal
   */
  open: PropTypes.bool.isRequired,

  /**
   *  The text at the top of the modal
   */
  headerText: PropTypes.string.isRequired,

  /**
   *  The text at the bottom of the modal
   */
  footerText: PropTypes.string.isRequired,

  /**
   * An object with 3 properties with exact 3 keys (33, 66, 99) that
   * indicates the text that is going to be switched during the interval
   */
  progressTextReference: PropTypes.exact({
    33: PropTypes.string.isRequired,
    66: PropTypes.string.isRequired,
    99: PropTypes.string.isRequired,
  }).isRequired,

  /**
   *  The interval between the text switching (in seconds)
   */
  textSwitchIntervalSeconds: PropTypes.number.isRequired,
};
