import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import styled from '@emotion/styled';
import makeStyles from '@mui/styles/makeStyles';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Link,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import {
  Container,
  Grid,
} from '@mui/material';
import {
  FloatingCard,
  FormGrid,
} from '../themes/DefaultTheme';
import SectionHeader from '../shared/SectionHeader/SectionHeader';
import PasswordInput, { PasswordConfirmValidation, PasswordCreationValidation } from '../shared/PasswordInput/PasswordInput';
import { updatePasswordAction } from './forgot-password-slice';
import SpinnerCTA from '../shared/SpinnerCTA/SpinnerCTA';
import { LOGIN_PATH, FORGOT_PASSWORD_PATH } from '../shared/constants';
import ActionCard, { ERROR_TYPE } from '../shared/ActionCard/ActionCard';

const useStyles = makeStyles((theme) => ({
  formDescription: {
    paddingTop: theme.spacing(3),
    paddingBottom: 30,
    textAlign: 'center',
    maxWidth: 325,
    margin: '0 auto',
  },
  buttonWrapper: {
    marginTop: theme.spacing(1),
  },
  errorLink: {
    color: theme.palette.text.primary,
  },
}));

const ResetRoot = styled(Container)(({ theme }) => ({
  width: 386,
  [theme.breakpoints.down('md')]: {
    height: '100vh',
  },
}));

export default function ResetPasswordForm() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const FORM_TITLE = 'Reset your password';
  const FORM_DESCRIPTION = '';
  const resetToken = searchParams.get('resetToken');
  const updatingPassword = useSelector((state) => state.loading.resetPassword);
  const forgotPasswordError = useSelector((state) => state.error.resetPasswordExecutionError);
  const passwordResetComplete = useSelector((state) => state.forgotPassword.passwordResetComplete);
  const forgotPasswordErrorCode = useSelector(
    (state) => state.error.resetPasswordExecutionErrorCode,
  );
  const errorMessage = (forgotPasswordErrorCode && forgotPasswordErrorCode === 1050)
    ? (
      <>
        Your password reset link has expired or is invalid.&nbsp;
        <Link className={classes.errorLink} to={FORGOT_PASSWORD_PATH}>
          Please reset your password again.
        </Link>
      </>
    ) : 'There was an error processing your request. Please try again.';

  const schema = yup.object().shape({
    password: PasswordCreationValidation,
    confirmPassword: PasswordConfirmValidation('password'),
  });

  const methods = useForm({
    mode: 'all',
    criteriaMode: 'all',
    resolver: yupResolver(schema),
  });

  const { handleSubmit } = methods;

  const onSubmit = (data) => {
    dispatch(updatePasswordAction({ token: resetToken, newPassword: data.confirmPassword }));
  };

  useEffect(() => {
    if (passwordResetComplete) {
      // If true, then perform the actions to move to the next page (login)
      navigate(LOGIN_PATH);
    }
  }, [passwordResetComplete]);

  return (
    <>
      <Helmet>
        <title>Reset Password | LocaliQ</title>
      </Helmet>
      <ResetRoot>
        {forgotPasswordError && (
          <ActionCard
            id="reset-password-error-banner"
            type={ERROR_TYPE}
            style={{ marginBottom: 25 }}
          >
            {errorMessage}
          </ActionCard>
        )}
        <FloatingCard sx={{ padding: '24px' }}>
          <SectionHeader
            title={FORM_TITLE}
            description={FORM_DESCRIPTION}
            descriptionClass={classes.formDescription}
            ids = {{
              titleId: 'reset-password-title',
            }}
          />
          <FormProvider {...methods}>
            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <FormGrid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
                textAlign="left"
              >
                <Grid item xs={12}>
                  <PasswordInput
                    id="new-password"
                    name="password"
                    label="Password"
                    showRequirements
                    textId="reset-password-label"
                    errorId="reset-pw-error"
                  />
                </Grid>
                <Grid item xs={12}>
                  <PasswordInput
                    id="confirm-new-password"
                    name="confirmPassword"
                    label="Confirm Password"
                    textId="confirm-pw-label"
                    errorId="confirm-pw-error"

                  />
                </Grid>
                <Grid item xs={12} className={classes.buttonWrapper}>
                  <SpinnerCTA
                    variant="contained"
                    id="reset-password-btn"
                    type="submit"
                    showSpinner={updatingPassword}
                    buttonText="Reset Password"
                  />
                </Grid>
              </FormGrid>
            </form>
          </FormProvider>
        </FloatingCard>
      </ResetRoot>
    </>
  );
}
