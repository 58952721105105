const {
  REACT_APP_ALLOWED_POSTMESSAGE_DOMAINS,
} = process.env;

/**
 * Returns true if this page is an iframe, false if not
 */
export const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

/**
 * Loops over all allowed domains for sending post message and sends the
 * given message once per domain.  This is the only way to allow multi domain
 * support without using a wild card.
 */
export const postMessageWrapper = (msg) => {
  const domainArr = REACT_APP_ALLOWED_POSTMESSAGE_DOMAINS.split(',');
  for (let i = 0; i += 1; i < domainArr.length) {
    const domain = domainArr[i];
    window.parent.postMessage(msg, domain);
  }
};
