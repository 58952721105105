import ls from 'local-storage';
import {
  tracker,
  constants,
  events,
  eventProperties,
} from '@gannettdigital/localiq-dms-analytics-tracker';
import { CREATE_ACCOUNT_EXISTING_PLATFORM_USER_ERROR_TYPE } from '../CreateAccount/create-account-slice';

export const LOCALIQ_TYPE = 'LOCALiQ';
export const GOOGLE_TYPE = 'Google';
export const NEW_TYPE = 'New';
export const EXISTING_TYPE = 'Existing';

const {
  REACT_APP_MIXPANEL_ENABLED,
  REACT_APP_MIXPANEL_PROJECT_TOKEN,
} = process.env;

const MIXPANEL_ENABLE_CONVERTED = REACT_APP_MIXPANEL_ENABLED === 'true';
const emptyFunc = () => {};

/**
 * Handle initializing mixpanel tracking
 */
export const initializeMixpanel = () => {
  if (MIXPANEL_ENABLE_CONVERTED) {
    tracker.init([{
      name: constants.MIXPANEL_VENDOR_NAME,
      token: REACT_APP_MIXPANEL_PROJECT_TOKEN,
    }]);
  }
};

/**
 * Function to pull the hostname from the current page and
 * return it.
 * @returns string
 */
export const retrieveHostname = () => (
  window.location.hostname
);

/**
 * Function to pull the path from the current page and
 * return it. Trims off the starting slash
 * @returns string
 */
export const retrievePageName = () => (
  window.location.pathname.slice(1)
);

export const setLocationSuperProperties = () => {
  tracker.registerSuperProperties({
    [constants.PAGE]: retrievePageName(),
    [constants.PAGE_HOSTNAME]: retrieveHostname(),
  });
};

/**
 * Handle initial setup of user tracking and submitting the create account event.
 * Identifies the user by email, then sets the initial user props
 *
 * @param {string} email: String to identify the user
 * @param {string} source What the source of this account create was (LOCALiQ or Google)
 * @param {function} callback: Callback to execute after logging (redirect)
 */
export const createAccountTracking = (email, template, source, callback = emptyFunc) => {
  if (MIXPANEL_ENABLE_CONVERTED) {
    tracker.identifyUser(email);
    tracker.registerUserProperties({
      [constants.EMAIL]: email,
      [constants.ACCOUNT_CREATION_DATE]: new Date(),
      [constants.SEGMENT]: ls.get('segment'),
    }, callback);
    tracker.identifyUser(email);
  } else {
    // Since the above doesn't run when mixpanel is off, need to execute
    // the callback manually.
    callback();
  }
};

/**
 * Handle packaging and submitting the page viewed event.
 */
export const pageViewed = () => {
  tracker.trackEvent(events.PAGE_VIEWED, {
    [eventProperties.PAGE_TITLE]: document.title,
  });
};

/**
 * Handle the analytics portion of a successful sign in
 * @param {string} email Email associated with the signed in user
 * @param {string} source What the source of this sign in was (LOCALiQ or Google)
 * @param {function} callback: Callback to execute after logging (redirect)
 */
export const signInSuccess = (email, source, callback = emptyFunc) => {
  if (MIXPANEL_ENABLE_CONVERTED) {
    tracker.identifyUser(email);
    tracker.registerUserProperties({
      [constants.LAST_SIGN_IN_DATE]: new Date(),
      [constants.EMAIL]: email,
    }, callback);
    tracker.identifyUser(email);
    tracker.trackEvent(events.SIGN_IN_COMPLETE, {
      [eventProperties.SIGN_IN_METHOD]: source,
    });
  } else {
    // Since the above doesn't run when mixpanel is off, need to execute
    // the callback manually.
    callback();
  }
};

/**
 * Determine the error message to send to mixpanel by the given code and type
 * @param {number} code
 * @param {String} type
 * @returns Friendly error message
 */
const determineReason = (code, type) => {
  let friendlyMessage = null;
  switch (code) {
    case CREATE_ACCOUNT_EXISTING_PLATFORM_USER_ERROR_TYPE:
      friendlyMessage = 'User exists elsewhere in platform';
      break;
    case 1003:
      friendlyMessage = 'Invalid credentials';
      break;
    case 100:
      friendlyMessage = 'Unknown error';
      break;
    default:
      friendlyMessage = code;
      break;
  }

  return `${type} - ${friendlyMessage}`;
};

/**
 * Handle tracking sign in failure
 */
export const signInFailure = (errorCode, loginType) => {
  if (MIXPANEL_ENABLE_CONVERTED) {
    const errorReason = determineReason(errorCode, loginType);
    tracker.trackEvent(events.SIGN_IN_ERROR, {
      [eventProperties.ERROR_REASON]: errorReason,
    });
  }
};

/**
 * Helper function for tracking business page viewed
 * @param {string} variant
 */
export const trackBusinessViewed = (variant) => {
  if (MIXPANEL_ENABLE_CONVERTED) {
    tracker.trackEvent(events.PAGE_VIEWED, {
      [eventProperties.PAGE_TITLE]: document.title,
      [eventProperties.VARIANT]: variant,
    });
  }
};

// Reset analytics on sign out
export const signOutUser = () => {
  if (MIXPANEL_ENABLE_CONVERTED) {
    tracker.clearTracking();
  }
};

export const trackCreateAccountBlocked = (blockReason, returnToUrl) => {
  if (MIXPANEL_ENABLE_CONVERTED) {
    tracker.trackEvent(events.CREATE_ACCOUNT_BLOCKED, {
      [eventProperties.BLOCKED_REASON]: blockReason,
      [eventProperties.RETURN_TO_URL]: returnToUrl ?? 'No return URL',
    });
  }
};

export const trackForgotPWRequest = () => {
  if (MIXPANEL_ENABLE_CONVERTED) {
    tracker.trackEvent(events.FORGOT_PW_REQUESTED, {});
  }
};

export const trackResetPWSuccess = () => {
  if (MIXPANEL_ENABLE_CONVERTED) {
    tracker.trackEvent(events.RESET_PW_SUCCESS, {});
  }
};
