import { Container } from '@mui/material';
import styled from '@emotion/styled';

const FormContainer = styled(Container)(({ theme }) => ({
  width: 500,
  margin: '0 auto',
  '@media (min-width: 600px)': {
    paddingLeft: 0,
    paddingRight: 0,
  },
  [theme.breakpoints.down('md')]: {
    padding: '0 20px 80px 20px',
    width: '100%',
  },
}));

export default FormContainer;
