import { of } from 'rxjs';
import {
  catchError, map, mergeMap, filter,
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { signOutUser } from '../analytics/analytics-utils';
import {
  BACKEND_ROOT,
} from '../shared/constants';
import {
  logoutAction,
  logoutSuccessAction,
  logoutFailureAction,
} from './logout-slice';
import { emptyAction } from '../shared/shared-actions';

const logoutUserEpic = (action$) => action$.pipe(
  filter(logoutAction.match),
  mergeMap(() => ajax({
    url: `${BACKEND_ROOT}logout`,
    method: 'POST',
    withCredentials: true,
  }).pipe(
    map(() => {
      signOutUser();
      return logoutSuccessAction();
    }),
    catchError((error) => of(logoutFailureAction(error))),
  )),
);

const logoutUserSuccessEpic = (action$) => action$.pipe(
  filter(logoutSuccessAction.match),
  mergeMap(() => {
    window.location.replace('/login');
    return of(emptyAction());
  }),
);

export default [
  logoutUserEpic,
  logoutUserSuccessEpic,
];
