import { Helmet } from 'react-helmet';
import styled from '@emotion/styled';
import { Container } from '@mui/system';
import { Typography } from '@mui/material';

const CardContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(5, 10),
  border: `1px solid ${theme.palette.common.black}`,
  backgroundColor: theme.palette.common.white,
  borderRadius: '8px',
  '> h4': {
    fontFamily: 'Unify Sans',
    fontWeight: 600,
    paddingBottom: theme.spacing(3),
  },
  '> p': {
    fontSize: '18px',
  },

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(3),
  },
}));

const MainContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(0, 2),
  },
}));

function CountryNotAllowedPage() {
  return (
    <MainContainer>
      <Helmet>
        <title>Country Not Allowed | LOCALiQ</title>
      </Helmet>
      <CardContainer>
        <Typography variant="h4">Our products are currently not available for EU/UK users. Let&apos;s get you back on track.</Typography>
        <Typography variant="body1">
          The service you are interested in is not designed for users in the European Union
          {' '}
          or United Kingdom.
          {' '}
          For products tailored for your market, please
          {' '}
          <a href="https://localiq.co.uk">reach out to our UK team.</a>
        </Typography>
      </CardContainer>
    </MainContainer>
  );
}

export default CountryNotAllowedPage;
