import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';

const Title = styled(Typography)(({ theme }) => ({
  margin: '0 auto',
  textAlign: 'center',
  color: theme.palette.common.black,
  fontSize: 24,
  lineHeight: '25px',
  letterSpacing: '0.25px',
}));

const useStyles = makeStyles((theme) => ({
  standardDescription: {
    paddingTop: theme.spacing(3),
    textAlign: 'center',
    maxWidth: 340,
    margin: '0 auto',
  },
}));
/**
 * A component used for the title and description for a form section
 * See proptypes below for param info.
 */
export default function SectionHeader({
  title,
  description,
  descriptionClass,
  ids
}) {
  const classes = useStyles();
  return (
    <>
      <Title variant="h1" id={ids?.titleId}>
        {title}
      </Title>
      {description && (
        <Typography variant="body1" className={`${classes.standardDescription} ${descriptionClass}`} id={ids?.descriptionId}>
          {description}
        </Typography>
      )}
    </>
  );
}

SectionHeader.propTypes = {

  /**
   * Title value
   */
  title: PropTypes.string.isRequired,

  /**
   * Description value
   */
  description: PropTypes.string,

  /**
   * An optional css class for the description
   */
  descriptionClass: PropTypes.string,

};

SectionHeader.defaultProps = {
  descriptionClass: '',
  description: null,
};
