import { catchError, mergeMap, filter } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import {
  BACKEND_ROOT,
} from '../shared/constants';
import {
  requestResetPasswordSuccessAction,
  requestResetPasswordErrorAction,
  requestResetPasswordAction,
  updatePasswordSuccessAction,
  updatePasswordErrorAction,
  updatePasswordAction,
} from './forgot-password-slice';
import { trackResetPWSuccess } from '../analytics/analytics-utils';

const requestResetPasswordEpic = (action$) => action$.pipe(
  filter(requestResetPasswordAction.match),
  mergeMap((action) => ajax({
    url: `${BACKEND_ROOT}forgot-pw`,
    method: 'POST',
    body: {
      email: action.payload.email,
      returnToUrl: action.payload.returnToUrl,
      template: action.payload.template,
    },
  }).pipe(
    // NOTE: Not identifying the user here, if a prepopulated email is in the params
    // they would be identified.  Not sure forgot password entry is
    // a reliable source to identify someone.
    mergeMap(() => of(requestResetPasswordSuccessAction())),
    catchError((error) => of(requestResetPasswordErrorAction(error))),
  )),
);

const updatePasswordEpic = (action$) => action$.pipe(
  filter(updatePasswordAction.match),
  mergeMap((action) => ajax({
    url: `${BACKEND_ROOT}update-pw`,
    method: 'POST',
    body: {
      token: action.payload.token,
      newPassword: action.payload.newPassword,
    },
  }).pipe(
    mergeMap(() => {
      trackResetPWSuccess();
      return [
        updatePasswordSuccessAction(),
      ];
    }),
    catchError((error) => of(updatePasswordErrorAction(error))),
  )),
);

export default [
  requestResetPasswordEpic,
  updatePasswordEpic,
];
