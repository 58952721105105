import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import {
  Route,
  Routes,
  useSearchParams,
} from 'react-router-dom';
import ls from 'local-storage';
import { DefaultTheme } from './themes/DefaultTheme';
import RequiresAuth from './RequiresAuth';
import RedirectToSignIn from './RedirectToSignIn';
import DefaultLayout from './layouts/DefaultLayout';
import EmbedLayout from './layouts/EmbedLayout';
import CreateAccountEmbedPage from './CreateAccount/CreateAccountEmbedPage';
import { storeQueryParamValuesAction, validateIpAddressAction } from './shared/app-slice';
import ForgotPasswordForm from './ForgotPassword/ForgotPasswordForm';
import {
  FORGOT_PASSWORD_PATH,
  LOGIN_PATH,
  LOGIN_EMBED_PATH,
  LOGOUT_PATH,
  REGISTER_PATH,
  REGISTER_EMBED_PATH,
  CREATE_BUSINESS_PATH,
  RESET_PASSWORD_PATH,
  TEMPLATE_LS_KEY,
  NOT_ALLOWED_PATH,
} from './shared/constants';
import ResetPasswordForm from './ForgotPassword/ResetPasswordForm';
import LoginPage from './Login/LoginPage';
import LoginEmbedPage from './Login/LoginEmbedPage';
import LogoutPage from './Logout/LogoutPage';
import NotFoundPage from './shared/NotFoundPage/NotFoundPage';
import { ScrollToTop } from './shared/hooks/scrollToTop';
import { useMountEffect } from './shared/hooks/useMountEffect';
import { MonitorPage } from './shared/hooks/monitorPage';
import { setLocationSuperProperties, pageViewed, initializeMixpanel } from './analytics/analytics-utils';
import CreateBusinessPage from './CreateBusiness/CreateBusinessPage';
import CountryNotAllowedPage from './CountryNotAllowed/CountryNotAllowedPage';
import CreateAccountPage from './CreateAccount/CreateAccountPage';
import { isIpLocationEnabled } from './shared/utils/app-utils';

const {
  REACT_APP_ENABLE_TRACKJS,
  REACT_APP_TRACKJS_TOKEN,
  REACT_APP_TRACKJS_PROJECT,
} = process.env;

export default function App() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  /**
   * Setup TrackJS, only run if this environment supports it
   */
  useEffect(() => {
    if (REACT_APP_ENABLE_TRACKJS === 'true') {
      if (window.TrackJS) {
        window.TrackJS.install({
          token: REACT_APP_TRACKJS_TOKEN,
          application: REACT_APP_TRACKJS_PROJECT,
        });
      }
    }
  }, []);

  useEffect(() => {
    const template = searchParams.get('template');
    dispatch(storeQueryParamValuesAction({
      redirectUri: searchParams.get('return_to'),
      email: searchParams.get('email'),
      firstName: searchParams.get('firstName'),
      lastName: searchParams.get('lastName'),
      telephoneNumber: searchParams.get('phoneNumber'),
      url: searchParams.get('url'),
      template: template || ls.get(TEMPLATE_LS_KEY),
      cid: searchParams.get('cid'),
    }));

    // If a template was provided in the URL set it in local storage
    // Override any existing value if present.
    if (template) {
      ls.set(TEMPLATE_LS_KEY, template);
    }
  }, []);

  // Setup mixpanel
  useMountEffect(() => {
    initializeMixpanel();
    setLocationSuperProperties();
    pageViewed();
  });

  // Setup segment
  useMountEffect(() => {
    const segment = ls.get('segment');
    if (!segment) {
      const min = 1;
      const max = 12;
      const rand = min + Math.random() * (max - min);
      ls.set('segment', parseInt(rand, 10));
    }
  });

  // Detect location
  useMountEffect(() => {
    // Force a specific IP with testIP query param (for testing mostly),
    // otherwise will detect the user's actual IP address
    const testIP = searchParams.get('testIP');
    if (isIpLocationEnabled()) {
      dispatch(
        validateIpAddressAction({
          testIP,
        }),
      );
    }
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={DefaultTheme}>
        <>
          <ScrollToTop />
          <MonitorPage />
          <Routes>
            <Route
              path={REGISTER_PATH}
              element={(
                <DefaultLayout>
                  <CreateAccountPage />
                </DefaultLayout>
              )}
            />
            <Route
              path={REGISTER_EMBED_PATH}
              element={(
                <EmbedLayout>
                  <CreateAccountEmbedPage />
                </EmbedLayout>
              )}
            />
            <Route
              path={CREATE_BUSINESS_PATH}
              element={(
                <RequiresAuth>
                  <DefaultLayout>
                    <CreateBusinessPage />
                  </DefaultLayout>
                </RequiresAuth>
              )}
            />
            <Route
              path={LOGIN_PATH}
              element={(
                <DefaultLayout>
                  <LoginPage />
                </DefaultLayout>
              )}
            />
            <Route
              path={LOGIN_EMBED_PATH}
              element={(
                <EmbedLayout>
                  <LoginEmbedPage />
                </EmbedLayout>
              )}
            />
            <Route
              path={FORGOT_PASSWORD_PATH}
              element={(
                <DefaultLayout>
                  <ForgotPasswordForm />
                </DefaultLayout>
              )}
            />
            <Route
              path={RESET_PASSWORD_PATH}
              element={(
                <DefaultLayout>
                  <ResetPasswordForm />
                </DefaultLayout>
              )}
            />
            <Route
              path={LOGOUT_PATH}
              element={(
                <DefaultLayout>
                  <LogoutPage />
                </DefaultLayout>
              )}
            />
            <Route
              path={NOT_ALLOWED_PATH}
              element={(
                <DefaultLayout>
                  <CountryNotAllowedPage />
                </DefaultLayout>
              )}
            />
            <Route
              exact
              path="/"
              element={
                <RedirectToSignIn />
              }
            />
            <Route
              path="*"
              element={(
                <DefaultLayout>
                  <NotFoundPage />
                </DefaultLayout>
              )}
            />
          </Routes>
        </>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
