import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { BasicLinearProgress, FloatingCard } from '../../themes/DefaultTheme';

const useStyles = makeStyles((theme) => ({
  progressBlockWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'column',
    justifyContent: 'center',
    height: 500,
    width: 500,
    [theme.breakpoints.down('md')]: {
      height: 375,
    },
  },
  progressWrapper: {
    margin: theme.spacing(3, 0),
    width: '40%',
    [theme.breakpoints.down('md')]: {
      width: '80%',
    },
  },
  progressBar: () => ({
    background: `linear-gradient(90deg, #0046FE 0%, #6F10F4 31.25%, #EB3776 67.71%, #FF6436 100%)`,
  }),
}));

/**
 * Card for use in loading/saving applications. Shows a linear progress bar
 * with designated text
 *
 * See below prop types for param info
 */
export default function LoadingCard({
  progressText,
  loading,
  onFinish,
}) {
  const [progress, setProgress] = useState(10);
  const [startTime] = useState(Date.now());
  const classes = useStyles({ progress });
  const minimumDisplayTime = 2000;
  let intervalTracker = null;

  const updateProgression = () => {
    // If the backend is finished, check to make sure we have run for at least 2 seconds,
    // then jump to 100 and close on next interation.
    const elapsedTime = Date.now() - startTime;
    const pastMinimum = elapsedTime > minimumDisplayTime;
    if (pastMinimum) {
      if (!loading) {
        setProgress(100);
        // Buy a bit more time for the bar to get to 100% before redirect
        setTimeout(onFinish, 500);
      }
    } else if (progress < 100) {
      const diff = Math.random() * 20;
      setProgress(Math.min(progress + diff, 100));
    }
  };

  useEffect(() => {
    intervalTracker = setInterval(
      updateProgression,
      200,
    );
    return () => clearInterval(intervalTracker);
  });

  return (
    <FloatingCard>
      <div className={classes.progressBlockWrapper}>
        <Typography variant="h5" component="div">
          {progressText}
        </Typography>
        <div className={classes.progressWrapper}>
          <BasicLinearProgress
            classes={{
              bar: classes.progressBar,
            }}
            variant="determinate"
            value={progress}
          />
        </div>
      </div>
    </FloatingCard>
  );
}

LoadingCard.propTypes = {
  /**
   * The text to show above the progress bar
   */
  progressText: PropTypes.string.isRequired,

  /**
   * Boolean to dictate whether the backend is processing the load
   */
  loading: PropTypes.bool.isRequired,

  /**
   * Callback function for when the loading time is completed
   */
  onFinish: PropTypes.func,
};

LoadingCard.defaultProps = {
  onFinish: () => {},
};
