import makeStyles from '@mui/styles/makeStyles';
import {
  Container,
  Grid,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ValidationProvider } from '@gannettdigital/shared-react-components';
import {
  FormGrid,
  PrimaryLinkButton,
} from '../themes/DefaultTheme';
import PasswordInput,
{
  PasswordEntryValidation,
} from '../shared/PasswordInput/PasswordInput';
import { loginAction, resetLoginErrorAction } from './login-slice';
import EmailInput, { EmailValidation } from '../shared/EmailInput/EmailInput';
import { FORGOT_PASSWORD_PATH, REGISTER_EMBED_PATH, SUPPORT_EMAIL, REGISTER_PATH } from '../shared/constants';
import TextLinkButton from '../shared/TextLinkButton/TextLinkButton';
import { getLoginErrorMessage } from '../shared/utils/login-utils';
import { PrimaryButton } from '../shared/styled/shared-styled-components';
import ActionCard, { ERROR_TYPE, SUCCESS_TYPE } from '../shared/ActionCard/ActionCard';

const useStyles = makeStyles((theme) => ({
  passwordBlock: {
    textAlign: 'left',
  },
  forgotPassword: {
    marginTop: theme.spacing(2),
    paddingLeft: 0,
  },
  signUpWrapper: {
    '&.MuiGrid-item': {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(6),
    },
  },
}));

export default function LoginEmbedForm() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const template = useSelector((state) => state.externalSettings.template);
  const defaultEmail = useSelector((state) => state.externalSettings.prePopulateEmail);
  const hasResetPassword = useSelector((state) => state.forgotPassword.passwordResetComplete);
  const hasSignInError = useSelector((state) => state.error.loginError);
  const errorStatusCode = useSelector((state) => state.error.loginErrorStatusCode);
  const enteredEmail = useSelector((state) => state.loading.enteredEmail);
  const loginFailedAttempts = useSelector((state) => state.error.loginFailedAttempts);
  const isLoginLocked = useSelector((state) => state.error.isLoginLocked);

  const schema = yup.object().shape({
    email: EmailValidation,
    password: PasswordEntryValidation,
  });

  const methods = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const { handleSubmit, trigger } = methods;

  const onSubmit = async (data) => {
    dispatch(resetLoginErrorAction());
    dispatch(loginAction(data.email, data.password));
  };

  const handleForgotPassword = () => {
    // Reset errors before leaving
    dispatch(resetLoginErrorAction());
    window.open(FORGOT_PASSWORD_PATH, '_blank');
  };

  const handleSignUpLink = () => {
    // Reset errors before leaving
    dispatch(resetLoginErrorAction());
    navigate(REGISTER_EMBED_PATH);
  };

  return (
    <Container>
      {hasResetPassword && (
        <ActionCard
          id="reset-banner"
          type={SUCCESS_TYPE}
        >
          Your password has been successfully reset.
        </ActionCard>
      )}
      {(hasSignInError || isLoginLocked) && (
        <ActionCard
          id="sign-in-error-banner"
          type={ERROR_TYPE}
        >
          {getLoginErrorMessage(errorStatusCode, enteredEmail, loginFailedAttempts)}
          {isLoginLocked && ( <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}.</a> )}
        </ActionCard>
      )}
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <FormGrid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
              textAlign="left"
            >
              <Grid item xs={12}>
                <EmailInput
                  id="email-input-login"
                  name="email"
                  defaultEmail={defaultEmail}
                  trigger={trigger}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} className={classes.passwordBlock}>
                <PasswordInput
                  id="login-password"
                  name="password"
                  label="Password"
                />
                <PrimaryLinkButton
                  className={classes.forgotPassword}
                  onClick={handleForgotPassword}
                >
                  Forgot password?
                </PrimaryLinkButton>
              </Grid>
              <Grid item xs={12}>
                <PrimaryButton
                  sx={{ mt: '10px' }}
                  variant="contained"
                  id="login-btn"
                  type="submit"
                >
                  {template.login.ctaText || 'Sign In'}
                </PrimaryButton>
              </Grid>
              <Grid item xs={12} className={classes.signUpWrapper}>
                <TextLinkButton
                  text="Don't have an account?"
                  linkText="Sign up"
                  linkCallback={handleSignUpLink}
                  btnPath={REGISTER_PATH}
                />
              </Grid>
            </FormGrid>
          </form>
        </FormProvider>
      </ValidationProvider>
    </Container>
  );
}
