import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 14,
    textAlign: 'left',
    color: theme.palette.common.black,
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      paddingLeft: 4,
    },
  },
  requirementHeader: {
    fontFamily: 'Unify Sans SemiBold',
  },
  successText: {
    color: '#257D0E',
  },
  errorText: {
    color: '#CD1950',
  },
  containIcon: {
    width: 24,
    minWidth: 24,
    color: '#404040',

    '& .MuiIcon-root': {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .MuiSvgIcon-root': {
      height: 8,
      width: 8,
    },
  },
  checkedIcon: {
    color: '#257D0E',
    '&.MuiSvgIcon-root': {
      height: 14,
      width: 14,
    },
  },
  errorIcon: {
    color: '#CD1950',
    height: 14,
    width: 14,
  },
  requirementListItem: {
    padding: 0,
    width: 174,
  },
  listItemText: {
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '0.13px',
  },
  containList: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  containSection: {
    paddingBottom: theme.spacing(1),
  },
}));

/**
 * Component for rendering input requirements
 */
export default function InputRequirements({
  requiredConfig,
  disallowedConfig,
  errorKey,
  twoColumn = false,
}) {
  const classes = useStyles();
  const {
    watch,
    formState: { errors },
  } = useFormContext();
  const activeErrorObj = errors[errorKey] ? errors[errorKey].types : [];
  const activeErrorKeys = Object.values(activeErrorObj).reduce((acc, keyValue) => {
    let accumulator = acc;
    if (Array.isArray(keyValue)) {
      accumulator = accumulator.concat(keyValue);
    } else {
      accumulator.push(keyValue);
    }
    return accumulator;
  }, []);
  const usingDisallowed = disallowedConfig.length > 0;
  const gridSize = usingDisallowed ? 6 : 12;
  const hasValue = watch(errorKey);

  const RequiredConfigComponent = ({
    sliceEntry,
    sliceEnd,
  }) => requiredConfig.slice(sliceEntry, sliceEnd).map((listEntry) => {
    const { normalIcon, checkedIcon, errorKey } = listEntry;
    const NormalIcon = normalIcon;
    const CheckedIcon = checkedIcon;
    let noError = hasValue === '' || hasValue === undefined || hasValue === null;

    if (Array.isArray(errorKey)) {
      noError = noError || errorKey.find((eKey) => activeErrorKeys.indexOf(eKey) >= 0);
    } else {
      // There is "no error" in the requirement case if there is no value
      // OR this requirement is unfulfilled (error field is non-null)
      noError = noError || activeErrorKeys.indexOf(errorKey) >= 0;
    }

    return (
      <ListItem key={listEntry.text} className={classes.requirementListItem}>
        <ListItemIcon className={classes.containIcon}>
          {noError && <NormalIcon />}
          {!noError && <CheckedIcon className={classes.checkedIcon} />}
        </ListItemIcon>
        <ListItemText
          primary={listEntry.text}
          classes={{
            primary: `${classes.listItemText} ${!noError ? classes.successText : ''}`,
          }}
        />
      </ListItem>
    );
  });

  const DisallowedConfigComponent = () => disallowedConfig.map((listEntry) => {
    const { errorIcon, checkedIcon, errorKey } = listEntry;
    const CheckedIcon = checkedIcon;
    const ErrorIcon = errorIcon;
    let noError = hasValue === '' || hasValue === undefined || hasValue === null;

    if (Array.isArray(errorKey)) {
      noError = noError
        || errorKey.every((eKey) => activeErrorKeys.indexOf(eKey) === -1);
    } else {
      // There is "no error" in the requirement case if there is no value OR
      // this requirement is unfulfilled (error field is non-null)
      noError = noError || activeErrorKeys.indexOf(errorKey) === -1;
    }
    return (
      <ListItem key={listEntry.text} className={classes.requirementListItem}>
        <ListItemIcon className={classes.containIcon}>
          {noError && <CheckedIcon className={classes.checkedIcon} />}
          {!noError && <ErrorIcon className={classes.errorIcon} />}
        </ListItemIcon>
        <ListItemText
          primary={listEntry.text}
          classes={{
            primary: `${classes.listItemText} ${noError ? classes.successText : classes.errorText}`,
          }}
        />
      </ListItem>
    );
  });

  return (
    <Grid item container xs={12} className={classes.root}>
      <Grid item container xs={gridSize}>
        <Grid item xs={12} className={classes.containSection}>
          <Typography variant="overline" className={classes.requirementHeader} id="pw-must-contain-text">Must contain:</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <List dense id="password-contains-list" className={classes.containList}>
            <RequiredConfigComponent
              sliceEntry={0}
              sliceEnd={(twoColumn ? requiredConfig.length / 2 : requiredConfig.length)}
            />
          </List>
        </Grid>
        {twoColumn && (
          <Grid item xs={12} md={6}>
            <List dense className={classes.containList}>
              <RequiredConfigComponent
                sliceEntry={(requiredConfig.length / 2)}
                sliceEnd={requiredConfig.length}
              />
            </List>
          </Grid>
        )}
      </Grid>
      {usingDisallowed && (
        <Grid item container xs={6}>
          <Grid item xs={12} className={classes.containSection}>
            <Typography className={classes.requirementHeader}>Cannot contain:</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <List dense className={classes.containList}>
              <DisallowedConfigComponent
                sliceEntry={0}
                sliceEnd={(disallowedConfig.length / 2)}
              />
            </List>
          </Grid>
          <Grid item xs={12} md={6}>
            <List dense className={classes.containList}>
              <DisallowedConfigComponent
                sliceEntry={(disallowedConfig.length / 2)}
                sliceEnd={disallowedConfig.length}
              />
            </List>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

InputRequirements.propTypes = {
  requiredConfig: PropTypes.array.isRequired,
  disallowedConfig: PropTypes.array,
  errorKey: PropTypes.string.isRequired,
  twoColumn: PropTypes.bool,
};

InputRequirements.defaultProps = {
  disallowedConfig: [],
  twoColumn: false,
};
