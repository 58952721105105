import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  loginFailureAction,
  googleLoginAction,
  loginAction,
  setEnteredEmailAction,
  validateAuth,
  validateAuthFailure,
  tokenValid,
  tokenInvalid,
} from '../../Login/login-slice';
import {
  createAccountAction,
  createAccountSuccessAction,
  createAccountDuplicateEmailErrorAction,
  createAccountFailureAction,
  createAccountExistingPlatformUserErrorAction,
  createAccountFinishedLoadingAction,
} from '../../CreateAccount/create-account-slice';
import {
  createBusinessAction,
  createBusinessErrorAction,
  createBusinessFinishedLoadingAction,
} from '../../CreateBusiness/create-business-slice';
import {
  requestResetPasswordAction,
  requestResetPasswordSuccessAction,
  requestResetPasswordErrorAction,
  updatePasswordSuccessAction,
  updatePasswordErrorAction,
  updatePasswordAction,
} from '../../ForgotPassword/forgot-password-slice';

const loadingSlice = createSlice({
  name: 'loading',
  initialState: {
    signIn: false,
    createAccount: false,
    createBusiness: false,
    forgotPassword: false,
    resetPassword: false,
    validatingAuth: false,
    enteredEmail: '',
    loginLoaded: false
  },
  extraReducers: (builder) => {
    builder.addCase(loginFailureAction, (state) => {
      state.signIn = false;
    });
    builder.addCase(googleLoginAction, (state) => {
      state.signIn = true;
      state.createAccount = true;
    });
    builder.addCase(loginAction, (state) => {
      state.signIn = true;
    });
    builder.addCase(setEnteredEmailAction, (state, action) => {
      state.enteredEmail = action.payload.email;
    });
    builder.addCase(createBusinessAction, (state) => {
      state.createBusiness = true;
    });
    builder.addCase(requestResetPasswordAction, (state) => {
      state.forgotPassword = true;
    });
    builder.addCase(updatePasswordAction, (state) => {
      state.resetPassword = true;
    });
    builder.addCase(validateAuth, (state) => {
      state.validatingAuth = true;
    });
    builder.addCase(tokenValid, (state) => {
      state.validatingAuth = false;
    });
    builder.addMatcher(isAnyOf(createAccountAction, createAccountSuccessAction), (state) => {
      state.createAccount = true;
    });
    builder.addMatcher(isAnyOf(
      createAccountDuplicateEmailErrorAction,
      createAccountFailureAction,
      createAccountExistingPlatformUserErrorAction,
      createAccountFinishedLoadingAction,
    ), (state) => {
      state.createAccount = false;
      state.signIn = false;
    });
    builder.addMatcher(isAnyOf(
      createBusinessErrorAction,
      createBusinessFinishedLoadingAction,
    ), (state) => {
      state.createBusiness = false;
    });
    builder.addMatcher(isAnyOf(
      requestResetPasswordSuccessAction,
      requestResetPasswordErrorAction,
    ), (state) => {
      state.forgotPassword = false;
    });
    builder.addMatcher(isAnyOf(updatePasswordSuccessAction, updatePasswordErrorAction), (state) => {
      state.resetPassword = false;
    });
    builder.addMatcher(isAnyOf(validateAuthFailure, tokenInvalid), (state) => {
      state.validatingAuth = false;
    });
  },
});

export default loadingSlice.reducer;
