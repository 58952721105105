import {
  addParamToURL,
} from './url-utils';
import {
  SOURCE_QUERY_PARAM_NAME,
  AUTH_TOKEN_QUERY_PARAM_NAME,
} from '../constants';

const {
  REACT_APP_DEFAULT_RETURN_TO_URL,
  REACT_APP_IP_LOCATION_CHECK_ENABLED,
} = process.env;

export const handleSiteRedirect = (url, source, authToken) => {
  let redirectUrl = url || REACT_APP_DEFAULT_RETURN_TO_URL;
  redirectUrl = addParamToURL(redirectUrl, AUTH_TOKEN_QUERY_PARAM_NAME, authToken);
  redirectUrl = addParamToURL(redirectUrl, SOURCE_QUERY_PARAM_NAME, source);
  window.location.replace(redirectUrl);
  // Can't clear the analytics user here because queue may still be active
};

export const isIpLocationEnabled = () => REACT_APP_IP_LOCATION_CHECK_ENABLED === 'true';
