import { useMemo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CreateBusinessForm from './CreateBusinessForm';
import CreateBusinessFormErrors from './CreateBusinessFormErrors';
import { createBusinessAction } from './create-business-slice';
import FormContainer from '../shared/FormContainer/FormContainer';
import { PHONE_NUMBER_REGEX, WEBSITE_URL_REGEX } from '../shared/constants';
import { EXISTING_TYPE, NEW_TYPE, trackBusinessViewed } from '../analytics/analytics-utils';
import { isValidUSPhoneNumberLength } from '../shared/utils/string-utils';

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  requiredField: {
    marginTop: '24px'
  },
}));
export default function CreateBusinessFormContainer({ createBusinessError }) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const externalSettings = useSelector((state) => state.externalSettings);
  const {
    prePopulateBusinessName,
    prePopulateWebsiteUrl,
    prePopulateBusinessPhoneNumber,
    template,
  } = externalSettings;

  const schema = yup.object().shape({
    businessName: yup.string().required('Business name is required'),
    websiteUrl: yup.string().required().matches(WEBSITE_URL_REGEX, { message: 'Invalid Website URL', excludeEmptyString: true }),
    businessPhoneNumber: yup.lazy((value) => (value
      ? yup.string().test('len', 'Phone number must be 10 digits long', (val) => {
        if (!val) {
          return false;
        }
        return isValidUSPhoneNumberLength(val);
      }).matches(PHONE_NUMBER_REGEX, 'Phone number is invalid')
      : yup.mixed().notRequired())),
  });

  const methods = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
    resolver: yupResolver(schema),
    defaultValues: useMemo(() => ({
      businessName: prePopulateBusinessName || '',
      websiteUrl: prePopulateWebsiteUrl || '',
      businessPhoneNumber: prePopulateBusinessPhoneNumber || '',
    }), [prePopulateBusinessName, prePopulateWebsiteUrl, prePopulateBusinessPhoneNumber]),
  });

  const onSubmit = useCallback((data) => {
    const { businessName, websiteUrl, businessPhoneNumber } = data;
    dispatch(
      createBusinessAction({
        businessName,
        websiteUrl,
        businessPhoneNumber,
        isExisting: prePopulateBusinessName
          || prePopulateWebsiteUrl
          || prePopulateBusinessPhoneNumber,
      }),
    );
  }, [dispatch, createBusinessAction]);

  useEffect(() => {
    trackBusinessViewed(
      prePopulateBusinessName || prePopulateWebsiteUrl
        || prePopulateBusinessPhoneNumber ? EXISTING_TYPE : NEW_TYPE,
    );
  }, []);

  return (
    <FormContainer>
      {createBusinessError && <CreateBusinessFormErrors />}
      <div className={classes.mainWrapper}>
        <CreateBusinessForm
          methods={methods}
          onSubmit={onSubmit}
          isWebsiteRequired
        />
      </div>
    </FormContainer>
  );
}

CreateBusinessFormContainer.propTypes = {
  /**
   * Indicates if there is an error creating the business or not
   */
  createBusinessError: PropTypes.bool,
};
