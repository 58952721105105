import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import LoginForm from './LoginForm';
import CookieChecker from '../shared/CookieChecker/CookieChecker';
import styled from '@emotion/styled';
import { IQLoadingSpinner } from '@gannettdigital/shared-react-components';

const SpinnerWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center'
}));

export default function LoginPage() {
  const dispatch = useDispatch();
  const signInProcessing = useSelector((state) => state.loading.signIn);
  const redirectPath = useSelector((state) => state.login.redirectPath);
  const [authReady, setAuthReady] = useState(false);

  const authFinished = () => {
    setAuthReady(true);
  };

  if (redirectPath) {
    return (
      <Navigate to={redirectPath} />
    );
  }

  return (
    <>
      <Helmet>
        <title>Log In | LocaliQ</title>
      </Helmet>
      {authReady ? <LoginForm showLoading={signInProcessing} authReady={authReady} /> : <SpinnerWrapper><IQLoadingSpinner /></SpinnerWrapper>}
      <CookieChecker checkComplete={authFinished} hideSkeleton={true} />
    </>
  );
}
