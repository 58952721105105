import { createSlice } from '@reduxjs/toolkit';

const createBusinessSlice = createSlice({
  name: 'createBusiness',
  initialState: {
    existingUser: null,
  },
  reducers: {
    createBusinessAction: () => {},
    createBusinessSuccessAction: () => {},
    createBusinessErrorAction: () => {},
    createBusinessFinishedLoadingAction: () => {},
    createBusinessExistingUserAction: (state) => {
      state.existingUser = true;
    },
  },
});

export const {
  createBusinessAction,
  createBusinessSuccessAction,
  createBusinessErrorAction,
  createBusinessFinishedLoadingAction,
  createBusinessExistingUserAction,
} = createBusinessSlice.actions;

export default createBusinessSlice.reducer;
