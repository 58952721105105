import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { pageViewed, setLocationSuperProperties } from '../../analytics/analytics-utils';
import { CREATE_BUSINESS_PATH, LOGOUT_PATH } from '../constants';

export function MonitorPage() {
  const location = useLocation();
  useEffect(() => {
    setLocationSuperProperties();
    // By the time we get to logout, the user is lost, so no need to record the view
    // Don't want to pollute this simple page view with complexities, so create_business
    // gets its own tracker
    if (location
      && location.pathname !== LOGOUT_PATH
      && location
      && location.pathname !== CREATE_BUSINESS_PATH
    ) {
      pageViewed();
    }
  }, [location]);

  return null;
}
