import TagManager from 'react-gtm-module';
import {
  CALL_TRACKER_SLUG, CHATBOT_SLUG, FREE_TIER, PREMIUM_LISTINGS_SLUG, PRO_TIER, SCHEDULER_SLUG,
} from '../shared/constants';
import { GOOGLE_ANALYTICS_SUPPORTED_EVENTS } from './analytics-constants';

/**
 * Initializes the tag manager tracking platform
 */
function initialize() {
  // Google Analytics
  const tagManagerArgs = {
    gtmId: 'GTM-W9SLPDB',
    events: {
      gaTriggerEvent: 'gaTriggerEvent',
    },
    dataLayerName: 'Freemium',
  };

  TagManager.initialize(tagManagerArgs);
}

const buildDataLayer = (allProperties) => (
  {
    allProperties,
    dataLayerName: 'Freemium',
  }
);

const eventLabelByProduct = (slug, tier) => {
  switch (slug) {
    case CHATBOT_SLUG:
      return tier === FREE_TIER ? 'ChatFree' : 'ChatPro';
    case CALL_TRACKER_SLUG:
      return 'CallRecorderFree';
    case SCHEDULER_SLUG:
      return tier === FREE_TIER ? 'SchedulingFree' : 'SchedulingPro';
    case PREMIUM_LISTINGS_SLUG:
      return tier === PRO_TIER ? 'ListingsPro' : 'ListingsReview';
    default:
      // If all else fails, return the slug (bundle most likely)
      return slug;
  }
};

// Builds the necessary data properties for tracking a google account register GA event
export const buildGoogleRegisterGAData = (redirectUri, cid) => {
  let eventLabel = '';
  let isFree = true;
  if (redirectUri) {
    // See if the url has slug/tier
    const redirectUrl = new URL(redirectUri);
    const product = redirectUrl.searchParams.get('product');
    const tier = redirectUrl.searchParams.get('tier');
    eventLabel = eventLabelByProduct(product, tier);
    isFree = tier === FREE_TIER || product === CALL_TRACKER_SLUG;
  } else {
    eventLabel = 'UnknownProduct';
  }
  return {
    event: 'gaProductEvent',
    gaEventAction: 'GoogleRegistrationStep1',
    gaEventLabel: eventLabel,
    gaEventCategory: isFree ? 'SelfServeFreeProducts' : 'SelfServeProducts',
    cidValue: cid,
  };
};

/**
 * Handles the specific event tracking for Google Analytics (tag manager)
 */
export const trackGAEvent = (name, properties) => {
  if (GOOGLE_ANALYTICS_SUPPORTED_EVENTS.indexOf(name) > -1) {
    TagManager.dataLayer(buildDataLayer(properties));
  }
};

// Fire off initialization when this loads to ensure access to the given platforms.
initialize();
