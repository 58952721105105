import { combineEpics } from 'redux-observable';
import appEpics from '../shared/app-epics';
import createBusinessEpics from '../CreateBusiness/create-business-epics';
import createAccountEpics from '../CreateAccount/create-account-epics';
import forgotPasswordEpics from '../ForgotPassword/forgot-password-epics';
import loginEpics from '../Login/login-epics';
import logoutEpics from '../Logout/logout-epics';

export default combineEpics(
  ...appEpics,
  ...createBusinessEpics,
  ...createAccountEpics,
  ...loginEpics,
  ...forgotPasswordEpics,
  ...logoutEpics,
);
