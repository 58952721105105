import { createSlice } from '@reduxjs/toolkit';
import { redirectAction } from '../shared/shared-actions';
import { loginSuccessAction } from '../Login/login-slice';
import { ACCOUNT_BLOCKED_ERROR_MESSAGE } from '../shared/constants';

const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState: {
    passwordResetEmailed: false,
    passwordResetComplete: false,
    redirectPath: null,
    isAccountBlocked: false,
    blockedEmail: '',
    errorStatusCode: 0,
    failedLoginAttempts: 0,
  },
  reducers: {
    requestResetPasswordAction: (state) => {
      state.isAccountBlocked = false;
      state.blockedEmail = '';
      state.errorStatusCode = 0;
      state.failedLoginAttempts = 0;
    },
    requestResetPasswordSuccessAction: (state) => {
      state.passwordResetEmailed = true;
    },
    requestResetPasswordErrorAction: (state, action) => {
      const { failedAttempts, message, statusCode } = action.payload.response;
      if (failedAttempts >= 8 || message === ACCOUNT_BLOCKED_ERROR_MESSAGE) {
        const parsedRequestPayload = JSON.parse(action.payload.request.body);
        state.isAccountBlocked = true;
        state.blockedEmail = parsedRequestPayload.email;
        state.errorStatusCode = statusCode;
        state.failedLoginAttempts = failedAttempts;
      }
    },
    updatePasswordAction: () => {},
    updatePasswordSuccessAction: (state) => {
      state.passwordResetEmailed = false;
      state.passwordResetComplete = true;
    },
    updatePasswordErrorAction: () => {},
    resetRequestPasswordErrors: (state) => {
      state.passwordResetEmailed = false;
      state.passwordResetComplete = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(redirectAction, (state, action) => {
      state.redirectPath = action.payload.redirectPath;
    });
    builder.addCase(loginSuccessAction, (state) => {
      state.passwordResetEmailed = false;
      state.passwordResetComplete = false;
    });
  },
});

export const {
  requestResetPasswordAction,
  requestResetPasswordSuccessAction,
  requestResetPasswordErrorAction,
  updatePasswordAction,
  updatePasswordSuccessAction,
  updatePasswordErrorAction,
  resetRequestPasswordErrors,
} = forgotPasswordSlice.actions;

export default forgotPasswordSlice.reducer;
