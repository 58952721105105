import { createSlice } from '@reduxjs/toolkit';
import { TEMPLATE_MAPPING, DEFAULT_TEMPLATE } from './template-config';
import { createAccountSuccessAction } from '../CreateAccount/create-account-slice';
import { loginSuccessAction } from '../Login/login-slice';

const appSlice = createSlice({
  name: 'externalSettings',
  initialState: {
    redirectUri: null,
    prePopulateEmail: null,
    prePopulateFirstName: null,
    prePopulateLastName: null,
    prepopulatePhoneNumber: null,
    prePopulateBusinessName: null,
    prePopulateWebsiteUrl: null,
    prePopulateBusinessPhoneNumber: null,
    template: DEFAULT_TEMPLATE,
    cid: null, // salesforce campaign id
    ipAddressValidationData: null,
  },
  reducers: {
    changeTemplateAction: (state, action) => {
      state.template = TEMPLATE_MAPPING[action.payload.template] || DEFAULT_TEMPLATE;
    },
    setRedirectUriAction: (state, action) => {
      state.redirectUri = action.payload.redirectUri;
    },
    storeQueryParamValuesAction: (state, action) => {
      state.redirectUri = action.payload.redirectUri;
      state.prePopulateEmail = action.payload.email;
      state.prePopulateFirstName = action.payload.firstName;
      state.prePopulateLastName = action.payload.lastName;
      state.prepopulatePhoneNumber = action.payload.phoneNumber;
      state.prePopulateWebsiteUrl = action.payload.url;
      state.template = TEMPLATE_MAPPING[action.payload.template] || DEFAULT_TEMPLATE;
      state.cid = action.payload.cid;
    },
    validateIpAddressAction: () => {},
    validateIpAddressSuccessAction: (state, action) => {
      // TODO: Bring this back after release
      // const template = action.payload.response.countryCode !== 'CA'
      //   ? DEFAULT_TEMPLATE
      //   : {
      //     ...DEFAULT_TEMPLATE,
      //     createAccount: {
      //       ...DEFAULT_TEMPLATE.createAccount,
      //       termsAndServicesText: 'I agree to receive communications from LocaliQ.',
      //       shortenedLegalText: true,
      //     },
      //   };
      state.ipAddressValidationData = action.payload.response;
      state.template = TEMPLATE_MAPPING[action.payload.template] || DEFAULT_TEMPLATE;
    },
    validateIpAddressErrorAction: () => {},
  },
  extraReducers: (builder) => {
    builder.addCase(createAccountSuccessAction, (state, action) => {
      state.prePopulateEmail = action.payload.email;
      state.prePopulateFirstName = action.payload.firstName;
      state.prePopulateLastName = action.payload.lastName;
      state.prePopulateBusinessName = action.payload.businessName;
      state.prePopulateWebsiteUrl = state.prePopulateWebsiteUrl || action.businessUrl;
      state.prePopulateBusinessPhoneNumber = action.payload.businessPhone;
    });
    builder.addCase(loginSuccessAction, (state, action) => {
      state.prePopulateBusinessName = action.payload.businessName;
      state.prePopulateWebsiteUrl = state.prePopulateWebsiteUrl || action.payload.businessUrl;
      state.prePopulateBusinessPhoneNumber = action.payload.businessPhone;
    });
  },
});

export const {
  changeTemplateAction,
  setRedirectUriAction,
  storeQueryParamValuesAction,
  validateIpAddressAction,
  validateIpAddressSuccessAction,
  validateIpAddressErrorAction,
} = appSlice.actions;

export default appSlice.reducer;
