import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import LoadingCard from '../shared/LoadingCard/LoadingCard';
import LoginEmbedForm from './LoginEmbedForm';

export default function LoginEmbedPage() {
  const signInProcessing = useSelector((state) => state.loading.signIn);
  const [showLoading, setShowLoading] = useState(false);
  const template = useSelector((state) => state.externalSettings.template);
  const isLoginLocked = useSelector((state) => state.error.isLoginLocked);

  const loadingFinished = () => {
    setShowLoading(false);
  };

  useEffect(() => {
    if (signInProcessing && !isLoginLocked) {
      setShowLoading(true);
    }
  }, [signInProcessing, isLoginLocked]);

  return (
    <>
      <Helmet>
        <title>Log In | LocaliQ</title>
      </Helmet>
      {!showLoading && <LoginEmbedForm /> }
      {showLoading && (
        <LoadingCard
          progressText={template.login.loadingText || 'Signing In'}
          loading={signInProcessing}
          onFinish={loadingFinished}
        />
      )}
    </>
  );
}
