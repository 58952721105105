import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useEffect, useState } from 'react';
import CreateAccountEmbedForm from './CreateAccountEmbedForm';
import CreateAccountSkeleton from './CreateAccountSkeleton/CreateAccountSkeleton';
import LoadingCard from '../shared/LoadingCard/LoadingCard';
import { useMountEffect } from '../shared/hooks/useMountEffect';

export default function CreateAccountEmbedPage() {
  const createInProgress = useSelector((state) => state.loading.createAccount);
  const [showLoading, setShowLoading] = useState(false);
  const [pageMounted, setPageMounted] = useState(false);
  const template = useSelector((state) => state.externalSettings.template);

  const loadingFinished = () => {
    setShowLoading(false);
  };

  useMountEffect(() => {
    setPageMounted(true);
  });

  useEffect(() => {
    if (createInProgress) {
      setShowLoading(true);
    }
  }, [createInProgress]);

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LekSb8aAAAAANt4t-hjgX4Sw9l-VXhZr_5p82V_"
      scriptProps={{ async: true, defer: true }}
    >
      <Helmet>
        <title>Create Account | LocaliQ</title>
      </Helmet>
      {pageMounted && !showLoading && <CreateAccountEmbedForm />}
      {pageMounted && showLoading
        && (
          <LoadingCard
            progressText={template.createAccount.loadingText || 'Creating Account'}
            loading={createInProgress}
            onFinish={loadingFinished}
          />
        )}
      {!pageMounted && <CreateAccountSkeleton />}
    </GoogleReCaptchaProvider>
  );
}
