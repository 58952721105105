import PropTypes from 'prop-types';
import { IQLoadingSpinner } from '@gannettdigital/shared-react-components';
import makeStyles from '@mui/styles/makeStyles';
import { PrimaryButton } from '../styled/shared-styled-components';

const useStyles = makeStyles((theme) => ({
  spinner: {
    zIndex: theme.zIndex.drawer,
    marginRight: theme.spacing(2),
  },
  adjustBtn: {
    paddingLeft: theme.spacing(1),
    '& div': {
      marginRight: theme.spacing(1),
    },
  },
  btn: {
    borderRadius: '12px',
  }
}));

/**
 * Component for a CTA that triggers a spinner when clicked.
 * This component passes through additional props to you can set showSpinner in the parent
 * component using an onClick if need be.
 */
export default function SpinnerCTA({
  id,
  variant,
  type,
  buttonText,
  showSpinner,
  ...buttonProps
}) {
  const classes = useStyles();
  return (
    <PrimaryButton
      variant={variant}
      id={id}
      type={type}
      className={`${showSpinner ? classes.adjustBtn : classes.btn}`}
      disabled={showSpinner}
      {...buttonProps}
    >
      {showSpinner && (
        <IQLoadingSpinner size={20} />
      )}
      {buttonText}
    </PrimaryButton>
  );
}

SpinnerCTA.propTypes = {

  /**
   * Unique ID of this component
   */
  id: PropTypes.string.isRequired,

  /**
   * Material UI button variant
   */
  variant: PropTypes.string,

  /**
   * Type of the button (submit etc)
   */
  type: PropTypes.string,

  /**
   * Text of the button
   */
  buttonText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,

  /**
   * WHether to show the spinner
   */
  showSpinner: PropTypes.bool.isRequired,

};

SpinnerCTA.defaultProps = {
  variant: 'contained',
  type: 'submit',
};
