import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import CreateAccountForm from './CreateAccountForm';
import LoadingCard from '../shared/LoadingCard/LoadingCard';
import CookieChecker from '../shared/CookieChecker/CookieChecker';
import { useMountEffect } from '../shared/hooks/useMountEffect';
import { LoadingWrapper } from '../shared/styled/shared-styled-components';
import './create-account.css';

export default function CreateAccountPage() {
  const createInProgress = useSelector((state) => state.loading.createAccount);
  const redirectPath = useSelector((state) => state.createAccount.redirectPath);
  const [showLoading, setShowLoading] = useState(false);
  const [pageMounted, setPageMounted] = useState(false);
  const [authReady, setAuthReady] = useState(false);
  const template = useSelector((state) => state.externalSettings.template);

  const loadingFinished = () => {
    setShowLoading(false);
  };

  const authFinished = () => {
    setAuthReady(true);
  };

  useMountEffect(() => {
    setPageMounted(true);
  });

  useEffect(() => {
    if (createInProgress) {
      setShowLoading(true);
    }
  }, [createInProgress]);

  if (redirectPath) {
    return <Navigate to={redirectPath} />;
  }

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LekSb8aAAAAANt4t-hjgX4Sw9l-VXhZr_5p82V_"
      scriptProps={{ async: true, defer: true }}
    >
      <Helmet>
        <title>Create Account | LocaliQ</title>
      </Helmet>
      <CookieChecker checkComplete={authFinished} hideSkeleton={true} />
      {pageMounted && !showLoading && authReady && <CreateAccountForm />}
      {pageMounted && showLoading && authReady
        && (
          <LoadingWrapper>
            <LoadingCard
              progressText={template.createAccount.loadingText || 'Creating your Account'}
              loading={createInProgress}
              onFinish={loadingFinished}
            />
          </LoadingWrapper>
        )}
    </GoogleReCaptchaProvider>
  );
}
