import React from 'react';
import { Container, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    margin: '0 auto',
    paddingTop: theme.spacing(5),
    color: theme.palette.common.black,
  },
  title: {
    color: theme.palette.common.black,
    fontFamily: 'Unify Sans Bold',
    fontSize: 60,
    paddingBottom: theme.spacing(5),
  },
  subTitle: {
    fontFamily: 'Unify Sans SemiBold',
    fontSize: 18,
    paddingBottom: 10,
  },
  description: {
    fontSize: 16,
  },
}));

export default function NotFoundPage() {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Typography variant="h1" className={classes.title}>404</Typography>
      <Typography variant="h4" className={classes.subTitle}>The page you&#39;re looking for cannot be found.</Typography>
      <Typography variant="body1" className={classes.description}>The URL may have been mispelled, or the page may have moved.</Typography>
    </Container>
  );
}
