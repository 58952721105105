import { createSlice } from '@reduxjs/toolkit';

const logoutSlice = createSlice({
  name: 'logout',
  initialState: {},
  reducers: {
    logoutAction: () => {},
    logoutSuccessAction: () => {},
    logoutFailureAction: () => {},
  },
});

export const {
  logoutAction,
  logoutSuccessAction,
  logoutFailureAction,
} = logoutSlice.actions;

export default logoutSlice.reducer;
