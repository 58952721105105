import { useEffect, useMemo } from 'react';
import {
  Grid,
  Link,
  Typography,
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { GoogleReCaptcha, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {
  IQCheckbox, IQFormInput, ValidationProvider, IQButtonLink,
} from '@gannettdigital/shared-react-components';
import * as yup from 'yup';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { isMobile } from 'react-device-detect';
import { createAccountAction, resetCreateAccountError } from './create-account-slice';
import PasswordInput, { PasswordCreationValidation } from '../shared/PasswordInput/PasswordInput';
import EmailInput, { EmailValidation } from '../shared/EmailInput/EmailInput';
import TextLinkButton from '../shared/TextLinkButton/TextLinkButton';
import {
  FIRST_NAME_IS_REQUIRED_MESSAGE,
  FIRST_NAME_NO_SPACES_MESSAGE,
  LAST_NAME_IS_REQUIRED_MESSAGE,
  LAST_NAME_NO_SPACES_MESSAGE,
  LOGIN_PATH,
  NO_SPACES_REGEX,
  privacyPolicyLink,
  selfServiceTerm,
  SUPPORT_EMAIL,
} from '../shared/constants';
import {
  ContentFormGrid, CustomButton, GoogleBtnGridRow, LineBlock, PrimaryButton, SpacedGrid,
} from '../shared/styled/shared-styled-components';
import GoogleLoginButton from '../Login/SocialLogin/GoogleLoginButton';
import ActionCard, { ERROR_TYPE, WARNING_TYPE } from '../shared/ActionCard/ActionCard';
import GreCaptchaPrompt from './GreCaptchaPrompt';
import FormContainer from '../shared/FormContainer/FormContainer';
import LogoHeader from '../shared/LogoHeader/LogoHeader';

const {
  REACT_APP_GOOGLE_CLIENT_ID,
} = process.env;

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  headerTitle: {
    marginBottom: '24px',
    fontSize: '24px',
    fontFamily: 'Unify Sans SemiBold',
    textAlign: 'center',
    marginTop: 0,
  },
  termsWrapper: {
    color: theme.palette.common.black,
    textAlign: 'left',
    wordBreak: 'break-word',
    '&.MuiGrid-item': {
      paddingTop: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(2),
      },
    },
  },
  termsLink: {
    wordBreak: 'break-word',
    padding: '0 5px',
    textDecoration: 'underline',
  },
  privacyLink: {
    paddingRight: 5,
    textDecoration: 'underline',
  },
  signInLink: {
    paddingLeft: 4,
    paddingTop: 0,
    position: 'relative',
    top: 3,
  },
  link: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
  }
}));

export default function CreateAccountForm() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const template = useSelector((state) => state.externalSettings.template);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { termsAndServicesText } = template.createAccount;
  const defaultEmail = useSelector((state) => state.externalSettings.prePopulateEmail);
  const defaultFirstName = useSelector((state) => state.externalSettings.prePopulateFirstName);
  const defaultLastName = useSelector((state) => state.externalSettings.prePopulateLastName);
  const createAccountError = useSelector((state) => state.error.accountCreationError);
  const duplicateAccountError = useSelector((state) => state.error.duplicateError);
  const existingPlatformUserError = useSelector((state) => state.error.existingPlatformUserError);

  const responsiveCheck = {
    maxWidth: 500,
    width: isMobile ? 'auto' : 500,
  };

  const schemaShape = {
    email: EmailValidation,
    password: PasswordCreationValidation,
    firstName: yup.string()
      .required(FIRST_NAME_IS_REQUIRED_MESSAGE)
      .matches(NO_SPACES_REGEX, FIRST_NAME_NO_SPACES_MESSAGE),
    lastName: yup.string()
      .required(LAST_NAME_IS_REQUIRED_MESSAGE)
      .matches(NO_SPACES_REGEX, LAST_NAME_NO_SPACES_MESSAGE),
  };

  if (termsAndServicesText) {
    schemaShape.termsAndServices = yup.boolean();
  }

  const schema = yup.object().shape(schemaShape);

  const methods = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
    resolver: yupResolver(schema),
    defaultValues: useMemo(() => ({
      firstName: defaultFirstName || '',
      lastName: defaultLastName || '',
      email: defaultEmail || '',
      telephoneNumber: '',
      termsAndServices: false,
    }), [defaultEmail, defaultFirstName, defaultLastName]),
  });

  const {
    handleSubmit,
    trigger,
  } = methods;

  const onSubmit = async (data) => {
    resetCreateAccountError();
    const token = await executeRecaptcha('CREATE_ACCOUNT');
    dispatch(createAccountAction({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      password: data.password,
      telephoneNumber: null,
      recaptchaToken: token,
    }));
  };

  const handleSignInLink = (e) => {
    e.preventDefault();
    // Before we leave, reset any errors we see.
    dispatch(resetCreateAccountError());
    navigate(LOGIN_PATH);
  };

  return (
    <>
      <LogoHeader />
      <FormContainer>
        {createAccountError && (
          <ActionCard
            id="general-account-error-banner"
            type={ERROR_TYPE}
            style={{ marginBottom: 25 }}
          >
            An error has occured and we were unable to create your account. Please try again.
          </ActionCard>
        )}
        {duplicateAccountError && (
          <ActionCard
            id="duplicate-account-banner"
            type={WARNING_TYPE}
            style={{ marginBottom: 25 }}
          >
            An account with the specified email already exists. Please
            <IQButtonLink
              id="sign-in-link"
              className={classes.signInLink}
              onClick={handleSignInLink}
            >
              sign in.
            </IQButtonLink>
          </ActionCard>
        )}
        {existingPlatformUserError && (
          <ActionCard
            id="existing-platform-user-banner"
            type={WARNING_TYPE}
            style={{ marginBottom: 25 }}
          >
            It looks like this product is not part of your LocaliQ account.
            Contact your current rep to learn more about your LocaliQ
            and the solutions that are right for your business.
          </ActionCard>
        )}
        <div className={classes.mainWrapper}>
          <ValidationProvider schema={schema}>
            <FormProvider {...methods}>
              <form
                autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
                style={responsiveCheck}
              >
                <GoogleReCaptcha />
                <ContentFormGrid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <Typography id="social-signin-title" className={classes.headerTitle}>Create Your LocaliQ Account</Typography>
                  </Grid>
                  <GoogleBtnGridRow item xs={12}>
                    <GoogleLoginButton clientId={REACT_APP_GOOGLE_CLIENT_ID} signUp />
                  </GoogleBtnGridRow>
                  <SpacedGrid item xs={12}>
                    <LineBlock>OR</LineBlock>
                  </SpacedGrid>
                  <Grid item xs={12}>
                    <IQFormInput
                      name="firstName"
                      id="signup-first-name-input"
                      textId="signup-first-name-label"
                      labelText="First Name"
                      fullWidth
                      inputRadius='12px'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <IQFormInput
                      name="lastName"
                      id="signup-last-name-input"
                      textId="signup-last-name-label"
                      labelText="Last Name"
                      fullWidth
                      inputRadius='12px'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <EmailInput
                      name="email"
                      id="signup-email-input"
                      textId="signup-email-label"
                      defaultEmail={defaultEmail}
                      fullWidth
                      trigger={trigger}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <PasswordInput
                      id="signup-create-password-input"
                      textId="signup-create-password-label"
                      name="password"
                      label="Password"
                      showRequirements
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ display: 'none' }}>
                    <IQFormInput
                      name="telephoneNumber"
                      id="telephone-number-input"
                      labelText="Telephone Number"
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ display: termsAndServicesText ? 'block' : 'none' }}>
                    <IQCheckbox
                      field="termsAndServices"
                      id="signup-terms-services"
                      label={termsAndServicesText}
                      title=""
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.termsWrapper}>
                    <Typography variant="caption" id="signup-legal-text">
                      By clicking the &quot;Get Started&quot; button, you agree to our
                      <Link
                        className={classes.termsLink}
                        href={selfServiceTerm}
                        color="primary"
                        variant="inherit"
                        rel="noopener"
                        target="_blank"
                        id="signup-term-link"
                      >
                        Self-Service Terms,
                      </Link>
                      <Link
                        className={`${classes.privacyLink}`}
                        href={privacyPolicyLink}
                        color="primary"
                        variant="inherit"
                        rel="noopener"
                        target="_blank"
                        id="signup-privacy-link"
                      >
                        Privacy Policy
                      </Link>
                      and to receive B2B marketing communications from LocaliQ at the email and number you provided. To delete your free account, email <a target="_blank" href={`mailto:${SUPPORT_EMAIL}?subject=LocaliQ Assistance`}>{SUPPORT_EMAIL}</a>. HIPAA customers are not eligible for free products.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <CustomButton
                      variant="contained"
                      id="create-acct-btn"
                      type="submit"
                    >
                      {template.createAccount.ctaText || 'Create Account'}
                    </CustomButton>
                  </Grid>
                </ContentFormGrid>
              </form>
            </FormProvider>
          </ValidationProvider>
        </div>
        <TextLinkButton
          text="Already have an account?"
          linkText="Log in"
          btnPath={LOGIN_PATH}
          linkCallback={handleSignInLink}
          className={classes.link}
          ids={{
            linkId: "signup-have-acct-text",
            textId: "signup-signin-link"
          }}
        />
        <GreCaptchaPrompt sx={{ paddingTop: theme.spacing(1) }} />
      </FormContainer>
    </>
  );
}
