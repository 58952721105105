import { Card, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { ReactComponent as WarningIcon } from '../../images/warning-icon.svg';
import { ReactComponent as ErrorIcon } from '../../images/error-icon.svg';
import { ReactComponent as InfoIcon } from '../../images/informational-icon.svg';
import { ReactComponent as SuccessIcon } from '../../images/success-icon.svg';

const BasicCard = styled(Card)(() => ({
  margin: '0 auto',
  borderRadius: 12,
  border: '1px solid #CECFD0',
  boxShadow: 'none',
  borderLeft: 0,
}));

const ColorBar = styled('div')(({ bgColor, theme }) => ({
  minWidth: theme.spacing(1),
  backgroundColor: bgColor,
  borderRadius: '2px 0 0 2px',
}));

const MessageWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(1, 2),
  border: `1px solid ${theme.palette.info.light}`,
  borderLeft: 0,
  flex: 1,
}));

const Message = styled(Typography)(({ theme }) => ({
  fontFamily: 'Unify Sans',
  fontSize: 14,
  lineHeight: '25px',
  textAlign: 'left',
  marginTop: 4,
  paddingLeft: theme.spacing(2),
}));

const IconImageWrapper = styled('div')(({ colorFill }) => ({
  '& svg': {
    height: 32,
    width: 32,
    '& path': {
      fill: colorFill,
    },
  },
}));

export const SUCCESS_TYPE = 'SUCCESS_TYPE';
export const ERROR_TYPE = 'ERROR_TYPE';
export const INFO_TYPE = 'INFO_TYPE';
export const WARNING_TYPE = 'WARNING_TYPE';
const VALID_TYPES = [SUCCESS_TYPE, ERROR_TYPE, INFO_TYPE, WARNING_TYPE];

/**
 * Component for a card representing an action taken
 * (reset password result, error, success etc)
 *
 * See below prop types for param info
 */
export default function ActionCard({
  id,
  type,
  children,
  textId,
  ...props
}) {
  const theme = useTheme();
  let selectedIcon = null;
  let typeColor = theme.palette.primary.main;

  switch (type) {
    case SUCCESS_TYPE:
      typeColor = '#7DBE50';
      selectedIcon = (
        <IconImageWrapper colorFill={typeColor}>
          <SuccessIcon alt="success-icon" />
        </IconImageWrapper>
      );
      break;
    case ERROR_TYPE:
      typeColor = '#D7004B';
      selectedIcon = (
        <IconImageWrapper colorFill={typeColor}>
          <ErrorIcon alt="error-icon" />
        </IconImageWrapper>
      );
      break;
    case INFO_TYPE:
      typeColor = '#7826EC';
      selectedIcon = (
        <IconImageWrapper colorFill={typeColor}>
          <InfoIcon alt="info-icon" />
        </IconImageWrapper>
      );
      break;
    case WARNING_TYPE:
      typeColor = theme.palette.warning.main;
      selectedIcon = (
        <IconImageWrapper colorFill={typeColor}>
          <WarningIcon alt="warning-icon" />
        </IconImageWrapper>
      );
      break;
    default:
      break;
  }

  return (
    <BasicCard
      id={id}
      {...props}
    >
      <div style={{ display: 'flex', }}>
        <ColorBar bgColor={typeColor} />
        <MessageWrapper>
          {selectedIcon}
          <Message id={textId}>{children}</Message>
        </MessageWrapper>
      </div>
    </BasicCard>
  );
}

ActionCard.propTypes = {
  /**
   * Unique ID of this component
   */
  id: PropTypes.string.isRequired,

  /**
   * Message to display in the banner
   */
  children: PropTypes.node.isRequired,

  /**
   * The type to determine the styling of the banner
   */
  type: (props, propName, componentName) => {
    const passedType = props[propName];
    if (VALID_TYPES.indexOf(passedType) <= -1) {
      return new Error(
        `Invalid prop ${propName} supplied to ${componentName}. Check VALID_TYPES for allowable type values.`,
      );
    }
    return '';
  },
};

ActionCard.defaultProps = {
  type: SUCCESS_TYPE,
};
