import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import * as yup from 'yup';
import { IQInputEmail } from '@gannettdigital/shared-react-components';

export const EmailValidation = yup.string().email('Invalid email').required('Email is required');

export default function EmailInput({
  id,
  textId,
  name,
  defaultEmail,
  label,
  trigger,
  ...props
}) {
  const { setValue } = useFormContext();

  useEffect(() => {
    if (defaultEmail) {
      setValue(name, defaultEmail);
    }
  }, [defaultEmail, setValue]);

  const onChangeHandler = (e) => {
    const email = e.target.value;
    setValue(name, email.trim());
    trigger(name);
  };

  return (
    <IQInputEmail
      label={`${label}`}
      field={name}
      id={id}
      textId={textId}
      onChange={onChangeHandler}
      inputRadius="12px"
      {...props}
    />
  );
}

EmailInput.propTypes = {

  /**
   * Unique ID of this component
   */
  id: PropTypes.string.isRequired,

  /**
   * The unique name for this form field
   */
  name: PropTypes.string.isRequired,

  /**
   * Default value to use for the form field
   */
  defaultEmail: PropTypes.string,

  /**
   * The label that appears as a placeholder
   */
  label: PropTypes.string,
  trigger: PropTypes.func,
};

EmailInput.defaultProps = {
  label: 'Email',
  defaultEmail: '',
};
