import styled from '@emotion/styled';
import {
  Button,
  Grid,
} from '@mui/material';
import { borderRadius } from '@mui/system';
import { FormGrid } from '../../themes/DefaultTheme';

export const LineBlock = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  color: '#404040',
  fontSize: 12,
  fontFamily: 'Unify Sans SemiBold',
  '&::before, &::after': {
    content: '""',
    flex: 1,
    borderBottom: '1px solid #D7D7D7',
  },
  '&:not(:empty)::before': {
    marginRight: '16px',
  },
  '&:not(:empty)::after': {
    marginLeft: '16px',
  },
}));

export const SpacedGrid = styled(Grid)(({ theme }) => ({
  '&.MuiGrid-root.MuiGrid-item': {
    paddingTop: theme.spacing(3),
  },
}));

export const PrimaryButton = styled(Button)(({ theme }) => ({
  width: '100%',
  '&.MuiButtonBase-root.Mui-disabled.MuiButton-containedPrimary': {
    color: theme.palette.common.white,
    backgroundColor: 'rgba(0, 70, 255, 0.3)',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  },
}));

export const ContentFormGrid = styled(FormGrid)(({ theme }) => ({
  textAlign: 'left',
  border: `1px solid #CECFD0`,
  padding: '40px',
  backgroundColor: theme.palette.common.white,
  borderRadius: '16px',
}));

export const LoadingWrapper = styled('div')(({ theme }) => ({
  width: 'max-content',
  margin: '0 auto',
  [theme.breakpoints.down('md')]: {
    width: 336,
  },
}));

export const GoogleBtnGridRow = styled(Grid)(() => ({
  '&.MuiGrid-root.MuiGrid-item': {
    paddingTop: 0,
  },
}));

export const CustomButton = styled(PrimaryButton)(({ theme }) => ({
  borderRadius: '12px'
}));