import { createSlice } from '@reduxjs/toolkit';
import { redirectAction } from '../shared/shared-actions';

const loginSlice = createSlice({
  name: 'login',
  initialState: {
    redirectPath: null,
  },
  reducers: {
    loginAction: () => {},
    setEnteredEmailAction: () => {},
    loginSuccessAction: () => {},
    loginFailureAction: () => {},
    resetLoginErrorAction: () => {},
    validateAuth: () => {},
    validateAuthFailure: () => {},
    tokenValid: () => {},
    tokenInvalid: () => {},
    // Google Sign Up & Sign In Are the Same action
    googleLoginAction: () => {},
  },
  extraReducers: (builder) => {
    builder.addCase(redirectAction, (state, action) => {
      state.redirectPath = action.payload.redirectPath;
    });
  },
});

export const {
  loginAction,
  setEnteredEmailAction,
  loginSuccessAction,
  loginFailureAction,
  resetLoginErrorAction,
  validateAuth,
  validateAuthFailure,
  tokenValid,
  tokenInvalid,
  googleLoginAction,
} = loginSlice.actions;

export default loginSlice.reducer;
