import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import {
  Alert,
  Container,
} from '@mui/material';
import { logoutAction } from './logout-slice';
import { IQLoadingSpinner } from '@gannettdigital/shared-react-components';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  spinnerClass: {
    height: 150,
    width: 150,
  },
}));

export default function LoginPage() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const logoutError = useSelector((state) => state.error.logoutUserError);

  useEffect(() => {
    dispatch(logoutAction());
  }, []);

  return (
    <>
      <Helmet>
        <title>Logging Out | LocaliQ</title>
      </Helmet>
      <Container className={classes.root}>
        {logoutError && (
          <Alert severity="error">An unknown error occurred when signing out. Please refresh and try again.</Alert>
        )}
        {!logoutError && (
          <IQLoadingSpinner />
        )}
      </Container>
    </>
  );
}
