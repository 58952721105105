import styled from '@emotion/styled';
import {
  Container, Skeleton,
} from '@mui/material';
import SplitCard from '../../shared/SplitCard/SplitCard';

const SkeletonContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    paddingBottom: theme.spacing(10),
  },
}));

const LeftSideWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0 80px',
  [theme.breakpoints.down('md')]: {
    padding: '0 20px',
  },
}));

const BaseSkeleton = styled(Skeleton)(({ theme }) => ({
  width: 344,
  transform: 'scale(1)',
  [theme.breakpoints.down('md')]: {
    width: 288,
  },
}));

const InputSkeleton = styled(BaseSkeleton)(() => ({
  height: 44,
}));

const InputLabelSkeleton = styled(Skeleton)(({ theme }) => ({
  height: 24,
  width: 84,
  transform: 'scale(1)',
  marginBottom: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    width: 43,
  },
}));

const OrSpacerSkeleton = styled(BaseSkeleton)(({ theme }) => ({
  marginTop: theme.spacing(3),
  height: 24,
}));

const ParagraphSkeleton = styled(BaseSkeleton)(() => ({
  height: 60,
}));

const SectionWrapper = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const RightSideWrapper = styled('div')(({ theme }) => ({
  width: 500,
  [theme.breakpoints.down('md')]: {
    height: 185,
  },
}));

export default function CreateAccountSkeleton() {
  return (
    <SkeletonContainer>
      <SplitCard
        leftSideContent={(
          <LeftSideWrapper>
            <SectionWrapper>
              <InputSkeleton />
              <OrSpacerSkeleton />
            </SectionWrapper>
            <SectionWrapper>
              <InputLabelSkeleton />
              <InputSkeleton />
            </SectionWrapper>
            <SectionWrapper>
              <InputLabelSkeleton />
              <InputSkeleton />
            </SectionWrapper>
            <SectionWrapper>
              <InputLabelSkeleton />
              <InputSkeleton />
            </SectionWrapper>
            <SectionWrapper>
              <InputLabelSkeleton />
              <InputSkeleton />
            </SectionWrapper>
            <SectionWrapper>
              <ParagraphSkeleton />
            </SectionWrapper>
            <SectionWrapper>
              <InputSkeleton />
            </SectionWrapper>
          </LeftSideWrapper>
        )}
        rightSideContent={(
          <RightSideWrapper>
            <Skeleton width="100%" height="100%" sx={{ transform: 'scale(1)' }} />
          </RightSideWrapper>
        )}
      />
    </SkeletonContainer>
  );
}
