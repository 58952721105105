import { of } from 'rxjs';
import { catchError, mergeMap, filter } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import ls from 'local-storage';
import { tracker } from '@gannettdigital/localiq-dms-analytics-tracker';
import {
  createAccountAction,
  createAccountSuccessAction,
  createAccountDuplicateEmailErrorAction,
  createAccountFailureAction,
  createAccountExistingPlatformUserErrorAction,
} from './create-account-slice';
import {
  BACKEND_ROOT,
  CREATE_BUSINESS_PATH,
  TEMPLATE_LS_KEY,
  SEGMENT_LS_KEY,
} from '../shared/constants';
import { inIframe, postMessageWrapper } from '../shared/utils/iframe-utils';
import { createAccountTracking, LOCALIQ_TYPE, trackCreateAccountBlocked } from '../analytics/analytics-utils';
import { redirectAction } from '../shared/shared-actions';

const handleCreateError = (error, state) => {
  const errorResponse = error.response;
  const responseMessage = {
    type: 'REGISTER_FAILURE',
  };
  if (inIframe()) {
    postMessageWrapper(JSON.stringify(responseMessage));
  }
  if (errorResponse && errorResponse.statusCode === 1001) {
    trackCreateAccountBlocked(
      'Account already exists!',
      state.externalSettings.redirectUri,
    );
    return of(createAccountDuplicateEmailErrorAction(error));
  }
  if (errorResponse && errorResponse.statusCode === 1004) {
    trackCreateAccountBlocked(
      'Account already exists!',
      state.externalSettings.redirectUri,
    );
    return of(createAccountExistingPlatformUserErrorAction(error));
  }
  return of(createAccountFailureAction(error));
};

const createAccountEpic = (action$, state$) => action$.pipe(
  filter(createAccountAction.match),
  mergeMap((action) => ajax({
    url: `${BACKEND_ROOT}create_account`,
    method: 'POST',
    withCredentials: true,
    body: {
      firstName: action.payload.firstName,
      lastName: action.payload.lastName,
      email: action.payload.email,
      password: action.payload.password,
      phoneNumber: action.payload.telephoneNumber,
      recaptchaToken: action.payload.recaptchaToken,
      template: ls.get(TEMPLATE_LS_KEY) || 'default',
      distinctId: tracker.getTrackingId(),
      segment: Number(ls.get(SEGMENT_LS_KEY)),
    },
  }).pipe(
    mergeMap((response) => {
      ls.remove(TEMPLATE_LS_KEY);
      return of(createAccountSuccessAction({
        firstName: response.response.firstName,
        lastName: response.response.lastName,
        email: response.response.email,
        authToken: response.response.authToken,
        source: LOCALIQ_TYPE,
      }));
    }),
    catchError((error) => handleCreateError(error, state$.value)),
  )),
);

const createAccountSuccessEpic = (action$, state$) => action$.pipe(
  filter(createAccountSuccessAction.match),
  mergeMap((action) => {
    // Fire off analytics tracking to identify the user, then the successful registration
    createAccountTracking(
      action.payload.email,
      state$.value.externalSettings.template.key,
      action.payload.source,
      () => {
        // Both of these options take the user off site, no need to call action
        if (inIframe()) {
          const responseMessage = {
            type: 'REGISTER_SUCCESS',
            // TODO: Add in auth token once sso merged?
          };
          postMessageWrapper(JSON.stringify(responseMessage));
        }
      },
    );

    return of(redirectAction({ redirectPath: CREATE_BUSINESS_PATH }));
  }),
);

export default [
  createAccountEpic,
  createAccountSuccessEpic,
];
