import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { validateAuth } from '../../Login/login-slice';
import {
  AUTH_COOKIE_EXPIRATION_NAME,
  REFRESH_COOKIE_EXPIRATION_NAME,
  SOURCE_LOGIN,
} from '../constants';
import CreateAccountSkeleton from '../../CreateAccount/CreateAccountSkeleton/CreateAccountSkeleton';

/**
 * "Component" that handles checking for the localiq_auth_expiration cookie
 *
 * See below prop types for param info
 */
export default function CookieChecker({ checkComplete, hideSkeleton = false }) {
  const validatingAuth = useSelector((state) => state.loading.validatingAuth);
  const [inProgress, setInProgress] = useState(false);
  const [cookies] = useCookies([]);
  const expirationMilliseconds = cookies[AUTH_COOKIE_EXPIRATION_NAME];
  const refreshExpirationMillis = cookies[REFRESH_COOKIE_EXPIRATION_NAME];
  const dispatch = useDispatch();

  useEffect(() => {
    if (expirationMilliseconds || refreshExpirationMillis) {
      dispatch(validateAuth({ source: SOURCE_LOGIN }));
    } else {
      checkComplete();
    }
  }, [expirationMilliseconds, refreshExpirationMillis]);

  useEffect(() => {
    if (validatingAuth) {
      setInProgress(validatingAuth);
    } else if (inProgress) {
      checkComplete();
      setInProgress(false);
    }
  }, [validatingAuth, inProgress]);

  if (hideSkeleton) {
    return null;
  }

  return validatingAuth ? <CreateAccountSkeleton /> : null;
}

CookieChecker.propTypes = {
  /**
   * Callback function for when the cookie check is complete
   */
  checkComplete: PropTypes.func.isRequired,
};
